import { awsInstance } from "config";

export const generateImage = (prompt) => {
  return awsInstance.get(`/constructorassets/generate_tailored_images?prompt=${prompt}&big=true
`);
};

export const getTargetAudience = (project_description) => {
  return awsInstance.get(`/constructorassets/determine_target_audience?project_description =${project_description}
`);
};
