import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useSwipeHandlers from "./useSwipeHandlers";
import { getImage } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCombined } from "api";
import { actions } from "store/app";
import { defaultCategories, findBySlug } from "utils/categories";

export default function useDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();

  const { user } = useSelector((state) => state.user);

  const { title, category, isCreateFlow, image } = useSelector((state) => state.app.details);
  const [contents, setContents] = useState([]);
  const [addedContents, setAddedContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [drawer, setDrawer] = useState({
    isOpen: false,
    title: "",
    category: "",
    text: "",
    type: "",
    videoId: "",
    feedUrl: "",
  });
  const [search, setSearch] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [isCongratsOpen, setCongratsOpen] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);

  const { handleSwipeRight, handleSwipeLeft, setCurrentIndex } = useSwipeHandlers(contents, drawer, setDrawer);

  const divRef = useRef(null);

  //   const fetchImage = () => {
  //     return getImage(title);
  //   };

  const fetchData = useCallback(async (keyword, search = false) => {
    try {
      document.getElementById("top")?.scrollIntoView();
      setLoading(true);

      let cachedData = localStorage.getItem("keywordData");
      cachedData = cachedData ? JSON.parse(cachedData) : {};

      // Check if data for the specific keyword is already cached
      const resultData = cachedData[keyword];
      if (resultData) {
        if (search) {
          addedContents.forEach((content) => {
            const existingContent = resultData.find((d) => d.type === content.type);
            if (existingContent) {
              existingContent.data = [...existingContent.data, content];
            }
          });
        }
        setContents(resultData);
        setLoading(false);
        return;
      }

      const { data: response } = await fetchCombined(keyword);
      if (response?.contents) {
        const resultData = response.contents.map((content) => ({
          ...content,
          data: content.data.slice(0, 10),
        }));

        if (search) {
          addedContents.forEach((content) => {
            const existingContent = resultData.find((d) => d.type === content.type);
            console.log(existingContent);
            if (existingContent) {
              existingContent.data = [...existingContent.data, content];
            } else {
              resultData.push({
                type: content.type,
                data: [...content],
              });
            }
          });
        }

        setContents(resultData);
        cachedData[keyword] = resultData;
        localStorage.setItem("keywordData", JSON.stringify(cachedData));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async () => {
    await fetchData(search, true);
    setSearch("");
  };

  useEffect(() => {
    if (!slug) fetchData(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("top")?.scrollIntoView();

    return () => {
      localStorage.removeItem("keywordData");
    };
  }, []);

  useEffect(() => {
    if (!user && isCreateFlow) navigate("/auth/signIn");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isCreateFlow]);

  useEffect(() => {
    if (isCongratsOpen) {
      setTimeout(() => {
        setCongratsOpen(false);
        setIsPreviewMode(false);
        dispatch(
          actions.setDetails({
            title,
            category,
            image,
            isCreateFlow: false,
          })
        );
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCongratsOpen]);

  const selectedCategory = useMemo(
    () => defaultCategories.find((cat) => cat.category === category)?.data.find((d) => d.title === title),
    [title, category]
  );

  useEffect(() => {
    if (slug) {
      const categorySelected = findBySlug(slug);
      if (categorySelected) {
        const { category, title, image } = categorySelected;
        let img;
        if (!image || image === "") img = getImage(title);
        dispatch(
          actions.setDetails({
            title,
            category,
            image: img || image,
          })
        );
        fetchData(title);
      } else return navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const isAdded = (type, d) => {
    return addedContents.some((content) => {
      if (content.type === type) {
        switch (type) {
          case "language_model":
            return d.response === content.response;
          case "video":
            return d.video_id === content.video_id;
          case "podcast":
            return d.feed_url === content.feed_url;
          default:
            return false;
        }
      }
      return false;
    });
  };

  const handleAdd = (content, d) => {
    setAddedContents((prev) => {
      if (isAdded(content.type, d)) {
        return prev.filter((c) => {
          if (c.type === content.type) {
            switch (c.type) {
              case "language_model":
                return c.response !== d.response;
              case "video":
                return c.video_id !== d.video_id;
              case "podcast":
                return c.feed_url !== d.feed_url;
              default:
                return true;
            }
          }
          return true;
        });
      } else {
        return [
          ...prev,
          {
            type: content.type,
            ...d,
          },
        ];
      }
    });
  };

  return {
    selectedCategory,
    title,
    category,
    search,
    addedContents,
    contents,
    loading,
    drawer,
    isPrivate,
    isCongratsOpen,
    isPreviewMode,
    divRef,
    user,
    isCreateFlow,
    handleSwipeRight,
    handleSwipeLeft,
    setCurrentIndex,
    setIsPrivate,
    setDrawer,
    setCongratsOpen,
    setIsPreviewMode,
    setSearch,
    handleAdd,
    isAdded,
    handleSearch,
  };
}
