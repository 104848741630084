import { loginWithFirebaseAuthToken, registerUser } from "config/APIs/auth";
import { useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import firebase from "config/firebase.js";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { userUpdate } from "store/user";
import { useDispatch } from "react-redux";

const buttonStates = {
  init: {
    text: "Continue",
    disabled: false,
  },
  verifyNumber: {
    text: "Verifying Number",
    disabled: true,
  },
  sendOtp: {
    text: "Sending OTP",
    disabled: true,
  },
  askOtp: {
    text: "Verify OTP",
    disabled: false,
  },
  verifyOtp: {
    text: "Verifying OTP",
    disabled: true,
  },
  successOtp: {
    text: "Verified, you'll be redirected shortly",
    disabled: false,
  },
};

export default function SignupPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [option, setOption] = useState("phone");
  const [code, setCode] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const [buttonState, setButtonState] = useState(buttonStates["init"]);
  const handleChange = (e) => {
    if (option === "phone")
      setDetails((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    else
      setCode((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
  };
  const inputfocus = (e) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      const next = e.target.tabIndex - 2;
      if (next > -1) {
        e.target.form.elements[next].focus();
      }
    } else {
      const next = e.target.tabIndex;
      console.log(next);
      if (next < 6 && e.key !== "Shift") {
        e.target.form.elements[next].focus();
      }
    }
  };
  const handleSubmit = () => {
    setButtonState(buttonStates["verifyNumber"]);
    registerUser(details)
      .then((res) => {
        if (res.status === 201) {
          console.log(res?.data);

          setButtonState(buttonStates["sendOtp"]);
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("send-otp-button-v2", {
            size: "invisible",
          });
          const appVerifier = window.recaptchaVerifier;
          firebase
            .auth()
            .signInWithPhoneNumber("+91" + details?.phoneNumber, appVerifier)
            .then(function (confirmationResult) {
              setButtonState(buttonStates["askOtp"]);
              setOption("otp");
              window.confirmationResult = confirmationResult;

              console.log(confirmationResult);
            });
        }
      })
      .catch((e) => {
        console.info("Err in auth number", e, "\n", e?.response);
        setButtonState(buttonStates["init"]);
        if (e?.response?.status === 404) {
        }
      });
  };

  function otpSubmit() {
    setButtonState(buttonStates["verifyOtp"]);
    let OTP = code?.otp1 + code?.otp2 + code?.otp3 + code?.otp4 + code?.otp5 + code?.otp6;
    window.confirmationResult
      .confirm(OTP)
      .then((result) => {
        setButtonState(buttonStates["successOtp"]);

        result.user.getIdToken().then((res) => {
          console.log(res);
          loginWithFirebaseAuthToken(res)
            .then((res) => {
              console.log(res);
              const { user: cred, token } = res.data.data;
              cred["role"] = "admin";
              cred["loginType"] = "phone";
              dispatch(
                userUpdate({
                  user: cred,
                  token: token,
                })
              );
              localStorage.setItem("role", "admin");
              localStorage.setItem("login_type", "phone");

              navigate("/");
            })
            .catch((e) => {
              console.log(e);
            });
        });
      })
      .catch((error) => {
        setButtonState(buttonStates["init"]);
        setOption("phone");
        console.log(error);
      });
  }
  return (
    <div className="flex pt-5 pb-10 px-5 flex-col items-center gap-10 bg-white h-[calc(100svh-60px)] relative">
      <MdArrowBack
        color="#696763"
        size={24}
        className="absolute top-3 left-3 cursor-pointer"
        onClick={() => navigate("/auth/signIn")}
      />
      <div className="flex flex-col items-center gap-10 self-stretch mx-auto max-w-[560px] w-full">
        <img src="/assets/signup.png" alt="My Vibe" className="w-[90px] h-20 object-contain" />
        <div className="flex flex-col font-satoshi text-center">
          <p className="gradient text-2xl font-bold leading-9 tracking-[0.48px]">Oops!</p>
          <p className="text-center text-sm font-normal leading-5 tracking-[0.28px] capitalize text-primary-neutral-400">
            Looks like you’re not registered with us.
          </p>
        </div>
        <p className="text-base font-normal leading-5 tracking-[0.64px] text-left w-full capitalize text-primary-neutral-800">
          Please enter your details to register
        </p>
        <div className="flex flex-col items-start gap-8 self-stretch">
          {option === "phone" ? (
            <>
              {" "}
              <div className="flex flex-col gap-2 items-start justify-center w-full">
                <p className="text-center text-sm font-bold leading-5 tracking-[0.35px] capitalize text-primary-neutral-800">
                  Your Name
                </p>
                <input
                  type="text"
                  name="name"
                  value={details.name}
                  onChange={handleChange}
                  className="p-4 flex justify-center items-center gap-4 rounded-xl border border-primary-neutral-200 w-full font-satoshi text-sm font-normal tracking-[0.35px]"
                  placeholder="Full Name Here"
                />
              </div>
              <div className="flex flex-col gap-2 items-start justify-center w-full">
                <p className="text-center text-sm font-bold leading-5 tracking-[0.35px] capitalize text-primary-neutral-800">
                  Email
                </p>
                <input
                  type="text"
                  name="email"
                  value={details.email}
                  onChange={handleChange}
                  className="p-4 flex justify-center items-center gap-4 rounded-xl border border-primary-neutral-200 w-full font-satoshi text-sm font-normal tracking-[0.35px]"
                  placeholder="Email ID here"
                />
              </div>
              <div className="flex flex-col gap-2 items-start justify-center w-full">
                <p className="text-center text-sm font-bold leading-5 tracking-[0.35px] capitalize text-primary-neutral-800">
                  Mobile
                </p>
                <div className="flex p-4 justify-start font-satoshi text-sm font-normal tracking-[0.56px] items-center gap-4 self-stretch rounded-xl border border-primary-neutral-200 w-full">
                  <span>+91</span>
                  <input
                    name="phoneNumber"
                    type="text"
                    placeholder="00000 00000"
                    maxLength={10}
                    value={details.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col space-y-2">
              <label htmlFor="otp" className="sr-only text-white text-sm lg:text-base font-semibold font-satoshi">
                Enter OTP
              </label>
              <form className="inputField mt-2 py-3 flex items-center justify-between text-primary-blue-dark font-normal">
                <input
                  className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                  name="otp1"
                  type="number"
                  autoComplete="off"
                  value={code.otp1}
                  onChange={handleChange}
                  tabIndex="1"
                  maxLength={1}
                  onKeyUp={(e) => inputfocus(e)}
                />
                <input
                  className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                  name="otp2"
                  type="number"
                  autoComplete="off"
                  value={code.otp2}
                  onChange={handleChange}
                  tabIndex="2"
                  maxLength={1}
                  onKeyUp={(e) => inputfocus(e)}
                />
                <input
                  className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                  name="otp3"
                  type="number"
                  autoComplete="off"
                  value={code.otp3}
                  onChange={handleChange}
                  tabIndex="3"
                  maxLength={1}
                  onKeyUp={(e) => inputfocus(e)}
                />
                <input
                  className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                  name="otp4"
                  type="number"
                  autoComplete="off"
                  value={code.otp4}
                  onChange={handleChange}
                  tabIndex="4"
                  maxLength={1}
                  onKeyUp={(e) => inputfocus(e)}
                />
                <input
                  className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                  name="otp5"
                  type="number"
                  autoComplete="off"
                  value={code.otp5}
                  onChange={handleChange}
                  tabIndex="5"
                  maxLength={1}
                  onKeyUp={(e) => inputfocus(e)}
                />{" "}
                <input
                  className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                  name="otp6"
                  type="number"
                  autoComplete="off"
                  value={code.otp6}
                  onChange={handleChange}
                  tabIndex="6"
                  maxLength={1}
                  onKeyUp={(e) => inputfocus(e)}
                />
              </form>
              <p className="text-2xs lg:text-xs text-center font-lato text-primary-neutral-800">
                OTP Sent to mobile no. {details?.phoneNumber}
              </p>
            </div>
          )}
          <button
            id="send-otp-button-v2"
            onClick={() => {
              if (option === "phone") {
                handleSubmit();
              } else {
                otpSubmit();
              }
            }}
            style={{
              background: "linear-gradient(159deg, #33D6E1 3.93%, #75DD67 103.93%)",
            }}
            disabled={buttonState.disabled}
            className="flex text-white py-4 pl-10 pr-7 justify-center items-center gap-2 self-stretch rounded-full bg-black flex-[1_0_0]">
            <span className="font-satoshi font-bold leading-6">{buttonState.text}</span>
            <IoMdArrowForward size={24} />
          </button>
        </div>
      </div>
    </div>
  );
}
