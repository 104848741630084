import { FaAngleRight } from "react-icons/fa6";

export default function Navigation({ handleNext, handleBack }) {
  return (
    <div
      className="flex self-stretch bg-white w-full fixed bottom-0 z-20"
      style={{
        boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.16)",
      }}>
      <div
        onClick={handleBack}
        className="flex cursor-pointer py-6 pl-16 pr-12 text-[16px] justify-center items-center gap-2 flex-[1_0_0] rounded-[72px]">
        <p className="text-primary-neutral-500 font-satoshi font-bold leading-6 tracking-[0.32px] underline underline-offset-2">
          Back to Edit
        </p>
      </div>
      <div
        onClick={handleNext}
        className="flex cursor-pointer py-6 pl-16 pr-12 justify-center items-center gap-2 flex-[1_0_0] "
        style={{
          background: "linear-gradient(159deg, #2BB656 3.93%, #33D6E1 63.93%, #3996E3 103.93%)",
        }}>
        <p className="flex items-center text-[16px] font-satoshi font-bold leading-6 tracking-[0.32px] text-white">
          Publish <FaAngleRight size={20} color="white" />
        </p>
      </div>
    </div>
  );
}
