import PropTypes from "prop-types";

const Content = ({
    icon,
    title,
    hasStarted,
    id,
    showAddButton,
    isAdded = false,
    onClick = () => {},
    onAdd = () => {},
}) => {
    return (
        <div className="flex gap-4 cursor-pointer items-center" id={id}>
            <div
                onClick={onClick}
                className={`flex w-10 h-10 rounded-full ${
                    hasStarted
                        ? "bg-[#0497AE] text-white"
                        : "bg-[#EDFFFF] text-[#0497AE]"
                } items-center justify-center cursor-pointer `}
            >
                {icon}
            </div>
            <div className="flex-[1_0_0]" onClick={onClick}>
                <p className="font-satoshi line-clamp-2 text-primary-neutral-800 text-[12px] font-bold capitalize tracking-[0.56px]">
                    {title}
                </p>
            </div>
            {!showAddButton && (
                <button
                    onClick={onAdd}
                    className={`flex items-center py-2 px-3 rounded-full border-primary-neutral-200 ${
                        !isAdded
                            ? "border text-primary-neutral-400"
                            : "text-white"
                    }`}
                    style={{
                        background: isAdded
                            ? "linear-gradient(159deg, #2BB656 3.93%, #33D6E1 63.93%, #3996E3 103.93%), linear-gradient(103deg, #F57D34 0.3%, #C44EB9 100.3%)"
                            : "transparent",
                    }}
                >
                    <span className="font-satoshi text-[10px] font-bold leading-[15px] tracking-[0.2px] capitalize">
                        Add
                    </span>
                </button>
            )}
        </div>
    );
};

Content.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
    duration: PropTypes.string,
    progress: PropTypes.number,
    hasStarted: PropTypes.bool,
    onPlay: PropTypes.func,
};

export default Content;
