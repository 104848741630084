import { createSlice } from "@reduxjs/toolkit";
import { defaultCategories } from "utils/categories";

const initialState = {
  categories: defaultCategories,
  details: {
    title: "",
    category: "",
    isCreateFlow: false,
  },
  createModal: {
    open: false,
  },
  loading: false,
  error: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.details = { ...action.payload };
    },
    setCreateModal: (state, action) => {
      state.createModal.open = action.payload.open;
    },
  },
});
export const { actions, reducer } = appSlice;
export default reducer;
