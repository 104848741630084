import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  token: localStorage.getItem("token") || "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userUpdate: (state, action) => {
      if (action.payload?.user !== undefined) {
        let u = action.payload?.user;
        state.user = u;
        localStorage.setItem("user", JSON.stringify(u));
      }
      if (action.payload?.token !== undefined) {
        let t = action.payload?.token;
        state.token = t;
        localStorage.setItem("token", t);
      }
    },
  },
});

export const { userUpdate } = userSlice.actions;

export default userSlice.reducer;
