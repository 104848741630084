import axios from "axios";
import config from "config/APIEndpoints";

const BASE_API_URL = config.getEndpoint()?.vibeEndpoint + "/api/v1";
const AWS_API_URL = config.getEndpoint()?.awsEndpoint;

export { BASE_API_URL };

// Util
export const getHeader = () => {
    return {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        },
    };
};

export const getMultipartHeader = () => {
    return {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "content-type": "multipart/form-data",
        },
    };
};

export const getFormDataHeader = () => {
    return {
        headers: { "Content-Type": "multipart/form-data" },
    };
};

export const lifeInstance = axios.create({
    baseURL: BASE_API_URL + "/vibe",
});

export const publicInstance = axios.create({
    baseURL: BASE_API_URL + "/public",
});

export const awsInstance = axios.create({
    baseURL: AWS_API_URL,
});
