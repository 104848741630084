import Category from "components/explore/category";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions } from "store/app";

export default function Explore() {
    const { categories, loading } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleKeywordSelect = (title, category, image, slug) => {
        dispatch(
            actions.setDetails({
                title,
                category,
                image,
            })
        );
        if (slug) return navigate(`/discover/${slug}`);
        navigate("/discover");
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (loading) {
        return (
            <div className="fixed z-[60] h-screen w-screen top-0 text-white font-bold bg-black/70 flex justify-center items-center">
                Loading...
            </div>
        );
    }

    return (
        <>
            <section className="flex flex-col items-center gap-10 self-stretch py-[40px] px-[20px] md:px-20">
                <div className="w-full flex flex-row space-x-3 overflow-x-auto">
                    {categories?.map((item) => {
                        return (
                            <div
                                onClick={() => {
                                    let ele = document.getElementById(
                                        item?.category
                                    );
                                    if (ele) ele.scrollIntoView();
                                }}
                                className="border cursor-pointer min-w-max border-primary-neutral-200 rounded-lg py-1.5 px-2.5 flex flex-row items-center space-x-2 text-primary-neutral-500 font-satoshi text-2xs font-medium leading-4 tracking-[0.2px]"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                >
                                    <path
                                        d="M0.707107 7.5L7.5 0.707107L14.2929 7.5L7.5 14.2929L0.707107 7.5Z"
                                        stroke="#9C9A96"
                                    />
                                </svg>
                                <p>{item?.category}</p>
                            </div>
                        );
                    })}
                </div>
                {categories.map(({ category, data }, index) => (
                    <Category
                        category={category}
                        data={data}
                        key={index}
                        handleKeywordSelect={handleKeywordSelect}
                    />
                ))}
            </section>
        </>
    );
}
