import { categories } from "./helper";

function CategoriesMap({ selected, setSelected }) {
  return (
    <div className="flex flex-wrap gap-4 w-fit items-center justify-center">
      {categories.map((categoryItem, index) => (
        <div
          key={index}
          className={`flex h-20 cursor-pointer min-w-[300px] w-[300px] max-w-[300px] p-2 items-start gap-2 flex-[1_0_0] rounded-lg relative overflow-hidden ${
            selected === index ? "border-2 border-white/60" : ""
          } rounded-3xl p-5 h-[10rem]`}
          onClick={() => setSelected(index)}
          style={{ background: categoryItem.background }}
        >
          <p className="text-white font-satoshi font-semibold text-[1.5rem]  leading-5 tracking-[0.28px]">
            {categoryItem.category}
          </p>
          <img
            src={categoryItem.imageUrl}
            alt={categoryItem.category}
            className="absolute right-[-15px] bottom-[-30px] w-[10rem] h-[10rem] rounded-3xl transform rotate-[10deg] object-cover"
          />
        </div>
      ))}
    </div>
  );
}

function VibeName({ title, setTitle }) {
  return (
    <div className="flex flex-col gap-8 w-full">
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        className="flex p-4 sm:max-w-[360px] mx-auto w-full text-white placeholder:text-white/25 justify-center items-center gap-4 self-stretch focus:ring-0 outline-none focus:shadow-none ring-0 focus:border-0 focus:border-b border-0 border-b bg-transparent focus:!border-b-white border-b-white/50 font-satoshi text-center text-lg font-bold leading-8 tracking-[0.5px]"
        placeholder="Start Typing..."
      />
      <div className="flex px-2 justify-center items-start gap-4 content-start self-stretch flex-wrap">
        {[...Array(5)].map((_, i) => (
          <div className="flex gp-2 py-2 px-4 border cursor-pointer border-white/20 rounded-full text-primary-neutral-400 font-satoshi text-xs leading-5 capitalize tracking-[0.48px]">
            #Some Suggestion
          </div>
        ))}
      </div>
    </div>
  );
}

export { VibeName, CategoriesMap };
