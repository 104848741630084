import axios from "axios";

const BASE_URI = "https://kc-recsys-api-ei6fs56g4q-el.a.run.app";
// const BASE_URI = "http://localhost:8001";

export const generate = () => {
  return axios.get(BASE_URI + "/infocards/landing/generate");
};

export const getKeywords = () => {
  return axios.get(BASE_URI + "/infocards/generate_keywords");
};

export const fetchVideos = (keyword) => {
  return axios.get(BASE_URI + "/infocards/fetch_videos?keyword=" + new URLSearchParams(keyword));
};

// New function for the combined endpoint
export const fetchCombined = (keyword) => {
  // return axios.get(BASE_URI + "/infocards/landing/fetch_infocard?keyword=" + encodeURIComponent(keyword.trim()) );

  // return content fetched from the newly created myvibe service
  return axios.get("https://myvibe-510369198881.asia-south1.run.app/vibes/fetch_videos?keyword=" + new URLSearchParams(keyword));
};