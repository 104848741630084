import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { RiArrowRightSLine } from "react-icons/ri";
import { CategoriesMap, VibeName } from "./components";
import Drawer from "react-modern-drawer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "store/app";
import { categories } from "./helper";

export default function NewVibeModal() {
  const navigate = useNavigate();
  const { createModal } = useSelector((state) => state.app);
  const [selected, setSelected] = useState(-1);
  const [step, setStep] = useState(0);
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();

  const isDisabled = step === 0 ? selected < 0 : title.length === 0;

  const handleNavigate = async () => {
    // let img = await getImage(title);

    dispatch(
      actions.setDetails({
        title: title,
        category: categories[step].category,
        isCreateFlow: true,
        // image: img,
      })
    );
    toggleDrawer();
    navigate("/discover");
  };

  useEffect(() => {
    if (!createModal.open) {
      // actions.setDetails({
      //     title: "",
      //     category: "",
      //     isCreateFlow: false,
      // });
      setStep(0);
      setSelected(-1);
      setTitle("");
    }
  }, [createModal.open]);

  const toggleDrawer = () =>
    dispatch(actions.setCreateModal({ open: !createModal.open }));

  return (
    <Drawer
      open={createModal.open}
      onClose={toggleDrawer}
      direction="bottom"
      size="100svh"
      className="top-0 overflow-y-auto"
      lockBackgroundScroll
    >
      <section className="fixed newVibeModal top-0 left-0 w-full h-[100svh] z-10 overflow-y-auto flex flex-col items-center gap-16 flex-[1_0_0] py-6 px-5">
        <div className="flex items-center gap-4 self-stretch">
          <div
            className="flex gap-2 items-center p-2 rounded-full bg-white/10"
            onClick={toggleDrawer}
          >
            <MdArrowBack size={20} color="white" />
          </div>
          <span
            className="font-lato text-xl font-semibold leading-8"
            style={{
              background:
                "linear-gradient(159deg, #75DD67 3.93%, #33D6E1 103.93%)",
              WebkitTextFillColor: "transparent",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
            }}
          >
            New Vibe
          </span>
        </div>
        <div className="flex gap-8 flex-col items-center flex-[1_0_0] w-full">
          <p className="text-white font-lato text-lg font-medium leading-8 tracking-[0.72px] w-full text-left">
            {step === 0 ? (
              <>
                What are you <span className="gradient">Vibing</span> on?
              </>
            ) : (
              <>
                Give your <span className="gradient">Vibe</span> a name
              </>
            )}
          </p>

          {step === 0 ? (
            <div className="flex items-center justify-center w-full">
              <CategoriesMap selected={selected} setSelected={setSelected} />{" "}
            </div>
          ) : (
            <VibeName title={title} setTitle={setTitle} />
          )}
        </div>
        <button
          onClick={() => (step === 0 ? setStep(step + 1) : handleNavigate())}
          disabled={isDisabled}
          className={`py-4 pl-16 pr-12 flex items-center justify-center gap-2 rounded-full ${
            !isDisabled ? "opacity-100" : "opacity-50"
          } text-white font-satoshi font-bold tracking-[0.32px]`}
          style={{
            background:
              "linear-gradient(159deg, #2BB656 3.93%, #33D6E1 63.93%, #3996E3 103.93%)",
          }}
        >
          {step === 0 ? "Next" : "Done"}{" "}
          <RiArrowRightSLine size={20} color="white" />
        </button>
      </section>
    </Drawer>
  );
}
