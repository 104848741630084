import { MdNorthEast } from "react-icons/md";

export default function Category({ category, data, handleKeywordSelect }) {
  return (
    <div id={category} className="flex flex-col gap-[40px] items-center w-full scroll-m-10">
      <div className="flex flex-col items-start justify-center w-full">
        <p className="font-lato text-primary-neutral-500 text-xs font-normal leading-5 opacity-50">Explore</p>
        <p className="text-[20px] font-lato font-semibold text-primary-neutral-800 tracking-[0.8px] capitalize">
          {category}
        </p>
      </div>
      <div className="grid max-sm:grid-cols-1 max-md:grid-cols-2 max-lg:grid-cols-3 max-xl:grid-cols-4 grid-cols-5 w-full gap-6">
        {data?.map((d, index) => (
          <Card key={index} {...d} category={category} handleClick={handleKeywordSelect} />
        ))}
      </div>
    </div>
  );
}

const Card = ({ category, title, alias, image, slug, handleClick }) => {
  return (
    <div
      onClick={() => handleClick(title, category, image, slug)}
      className="cursor-pointer flex w-full p-4 bg-white flex-col items-start gap-4 rounded-2xl border backdrop-blur-[4px]"
      style={{
        border: "1px solid rgba(255, 255, 255, 0.08)",
        boxShadow: "0px 2px 12px -4px rgba(0, 0, 0, 0.10), 0px 10px 8px -6px rgba(0, 0, 0, 0.05)",
      }}>
      <div className="flex items-center gap-4">
        <img src={image || "/assets/images/card.png"} className="h-12 w-12 object-contain rounded-full" alt={title} />
        <p className="font-lato text-[18px] tracking-[0.36px] leading-7 font-bold text-primary-neutral-900">{title}</p>
      </div>
      <p className="font-satoshi text-[12px] tracking-[0.24px] font-normal leading-5 text-primary-neutral-400">
        {alias}
      </p>
      <div className="flex justify-between items-center self-stretch">
        <div className="flex items-center justify-center p-2 rounded-full bg-primary-neutral-50 text-[#9C9A96]">
          <MdNorthEast color="#9C9A96" size={16} />
        </div>
        <div className="flex items-center gap-1 text-white">
          <p className="font-satoshi underline leading-4 tracking-[0.2px] text-[10px] font-bold text-[#75DD67]">
            <span
              style={{
                background: "linear-gradient(159deg, #33D6E1 3.93%, #75DD67 103.93%)",
                WebkitTextFillColor: "transparent",
                WebkitBackgroundClip: "text",
              }}>
              Check out this vibe
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
