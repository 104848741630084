import { useState } from "react";

const useSwipeHandlers = (contents, drawer, setDrawer) => {
  const [currentIndex, setCurrentIndex] = useState({ type: "language_model", index: 0 });

  const updateDrawer = (type, index) => {
    console.log(type, index);
    const item = contents?.find((c) => c.type === type)?.data?.[index];
    console.log({
      ...drawer,
      isOpen: true,
      text: item?.response ?? item?.title,
      type,
      videoId: type === "video" ? item.video_id : "",
      feedUrl: type === "podcast" ? item.feed_url : "",
      disabledPrev: type === "language_model" && index === 0,
      disabledNext: type === "podcast" && index === contents?.find((c) => c.type === type)?.data?.length - 1,
    });
    if (item) {
      setDrawer({
        ...drawer,
        isOpen: true,
        text: item?.response ?? item?.title,
        type,
        videoId: type === "video" ? item.video_id : "",
        feedUrl: type === "podcast" ? item.feed_url : "",
        disabledPrev: type === "language_model" && index === 0,
        disabledNext: type === "podcast" && index === contents?.find((c) => c.type === type)?.data?.length - 1,
      });
    }
  };

  const getNextIndex = (type, index, direction) => {
    const arrayLength = contents?.find((c) => c.type === type)?.data?.length;
    if (direction === "right") {
      if (index < arrayLength - 1) {
        return { type, index: index + 1 };
      } else {
        const nextType = getNextType(type);
        return { type: nextType, index: 0 };
      }
    } else {
      if (index > 0) {
        return { type, index: index - 1 };
      } else {
        const prevType = getPrevType(type);
        const prevArrayLength = contents?.find((c) => c.type === prevType)?.data?.length;
        return { type: prevType, index: prevArrayLength - 1 };
      }
    }
  };

  const getNextType = (currentType) => {
    if (currentType === "language_model") return "video";
    if (currentType === "video") return "podcast";
    return "language_model";
  };

  const getPrevType = (currentType) => {
    if (currentType === "podcast") return "video";
    if (currentType === "video") return "language_model";
    return "podcast";
  };

  const handleSwipeRight = () => {
    const nextIndex = getNextIndex(currentIndex.type, currentIndex.index, "right");
    console.log(nextIndex, currentIndex);
    setCurrentIndex(nextIndex);
    updateDrawer(nextIndex.type, nextIndex.index);
  };

  const handleSwipeLeft = () => {
    const prevIndex = getNextIndex(currentIndex.type, currentIndex.index, "left");
    console.log(prevIndex, currentIndex);
    setCurrentIndex(prevIndex);
    updateDrawer(prevIndex.type, prevIndex.index);
  };

  return { handleSwipeRight, handleSwipeLeft, setCurrentIndex };
};

export default useSwipeHandlers;
