import { BsFillCollectionFill } from "react-icons/bs";
import { IoCompassOutline } from "react-icons/io5";
import { RiAddCircleFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "store/app";

export default function TabBar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  if (location.pathname.includes("discover")) return;

  const handleModalOpen = () => {
    if (!user) {
      navigate("/auth/signIn");
      return;
    }
    dispatch(actions.setCreateModal({ open: true }));
  };

  return (
    <>
      <section className="w-full bg-white fixed bottom-0 py-2 px-6 backdrop-blur-[4px] border-t border-primary-neutral-100">
        <div className="flex gap-4 items-center justify-center md:max-w-md mx-auto">
          <div className="flex flex-col w-full items-center gap-2 px-2 py-1 rounded-full cursor-pointer">
            <IoCompassOutline size={24} color="#282724" />
            <span className="font-satoshi text-primary-neutral-900 text-2xs leading-3 font-bold">Explore</span>
          </div>
          <div
            className="flex flex-col w-full items-center gap-2 px-2 py-1 rounded-full cursor-pointer"
            onClick={handleModalOpen}>
            <RiAddCircleFill size={24} color="#CFCDC9" />
            <span className="font-satoshi text-primary-neutral-300 text-2xs leading-3 font-bold">New Vibe</span>
          </div>
          <div className="flex flex-col w-full items-center gap-2 px-2 py-1 rounded-full cursor-pointer">
            <BsFillCollectionFill size={24} color="#CFCDC9" />
            <span className="font-satoshi text-primary-neutral-300 text-2xs leading-3 font-bold">Your Vibes</span>
          </div>
        </div>
      </section>
    </>
  );
}
