import { MdClose, MdOutlineSwipe } from "react-icons/md";
import Drawer from "react-modern-drawer";
import { ReactComponent as Podcast } from "assets/svg/podcast.svg";
import YouTube from "react-youtube";
import AudioPlayer from "./audioPlayer";
import { useEffect, useState } from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { motion } from "framer-motion";
import { useSwipeable } from "react-swipeable";

const DetailsDrawer = ({
  isOpen,
  title,
  category,
  text,
  type,
  videoId,
  isActive = false,
  toggleDrawer,
  handleSwipeRight,
  handleSwipeLeft,
  disabledPrev = false,
  disabledNext = false,
}) => {
  const [audioSrc, setAudioSrc] = useState("");
  const [overlay, setOverlay] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (localStorage.getItem("showSwipe") !== "false") {
        setTimeout(() => setOverlay(true), 1000);
        localStorage.setItem("showSwipe", "false");
      }
      setAudioSrc("https://pdst.fm/e/traffic.megaphone.fm/GLT6080607145.mp3?updated=1721614075");
    } else setAudioSrc("");
  }, [isOpen]);

  useEffect(() => {
    if (overlay) {
      setTimeout(() => setOverlay(false), 3000);
    }
  }, [overlay]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => !disabledNext && handleSwipeRight(),
    onSwipedRight: () => !disabledPrev && handleSwipeLeft(),
  });

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="bottom"
        size="100svh"
        className="top-0 overflow-y-auto"
        lockBackgroundScroll>
        {overlay && (
          <motion.div
            variants={{
              hidden: {
                opacity: 0,
              },
              visible: {
                opacity: 100,
                transition: {
                  duration: 0.5,
                },
              },
              exit: {
                opacity: 0,
              },
            }}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="fixed top-0 left-0 w-full h-[100svh] flex items-center justify-center z-10 bg-black/50"
            onClick={() => setOverlay(false)}>
            <div className="flex w-[360px] mx-auto px-[40px] flex-col items-center justify-center gap-6">
              <MdOutlineSwipe size={32} color="white" />
              <p className="text-white text-center font-satoshi font-bold leading-6 tracking-[0.64px]">
                Swipe left or Right to navigate through Content
              </p>
            </div>
          </motion.div>
        )}

        <div className="flex sticky top-0 w-full px-[12px] py-[20px] gap-4 items-center border-b border-b-primary-neutral-200 bg-white">
          <MdClose size={20} onClick={toggleDrawer} />
          <div className="flex flex-col flex-[1_0_0]">
            <p className="font-lato text-[18px] tracking-[0.72px] leading-7 font-bold text-primary-neutral-800">
              {title}
            </p>
            <p className="font-satoshi text-[12px] tracking-[0.48px] leading-5 font-normal text-primary-neutral-500">
              {category} (theme)
            </p>
          </div>
        </div>

        <div {...swipeHandlers} className="max-w-[800px] mx-auto overflow-y-scroll min-h-[calc(100svh-100px)]">
          {type === "language_model" && <p className="p-6 text-primary-neutral-800 font-light leading-6">{text}</p>}
          {type === "podcast" && (
            <div className="px-4 pt-4 flex flex-col gap-4 xs:gap-6 flex-[1_0_0] w-full h-full !pb-[75px]">
              <div className="flex w-full h-[280px] p-2 justify-center items-center mx-auto gap-2 rounded-2xl bg-[#FDF2FF]">
                <Podcast />
                <Podcast />
              </div>
              <p className="text-lg font-satoshi capitalize text-primary-neutral-800 font-bold leading-7">{text}</p>
              <AudioPlayer src={audioSrc} isOpen={isOpen} />
            </div>
          )}
          {type === "video" && (
            <div>
              <YouTube
                videoId={videoId}
                opts={{
                  height: "300px",
                  width: "100%",
                  playerVars: {
                    autoplay: 1,
                    controls: 1,
                    disablekb: 0,
                    modestbranding: 1,
                    rel: 0,
                    showinfo: 0,
                  },
                }}
                style={{
                  overflow: "hidden",
                  width: "100%",
                  height: "100%",
                  margin: "0",
                }}
              />
              <p className="text-lg px-4 py-2 font-satoshi capitalize text-primary-neutral-800 font-bold leading-7">
                {text}
              </p>
            </div>
          )}
        </div>
        <div className="sticky bottom-0 w-full flex p-4 justify-between items-center bg-white border-t border-t-primary-neutral-200">
          <div className="flex gap-3 items-center">
            <div
              className={`flex p-2 items-center ${
                disabledPrev ? "bg-primary-neutral-100" : "bg-primary-neutral-800 text-white"
              } rounded-full`}
              onClick={() => !disabledPrev && handleSwipeLeft()}>
              <RiArrowLeftSLine size={20} />
            </div>
            <div
              className={`flex p-2 items-center ${
                disabledNext ? "bg-primary-neutral-100" : "bg-primary-neutral-800 text-white"
              } rounded-full`}
              onClick={() => !disabledNext && handleSwipeRight()}>
              <RiArrowRightSLine size={20} />
            </div>
          </div>
          <button
            className={`flex items-center gap-2 py-2 px-3 rounded-full border-primary-neutral-200 ${
              !isActive ? "border text-primary-neutral-400" : "text-white"
            }`}>
            <span className="font-satoshi text-[14px] font-bold leading-[21px] tracking-[0.56px] capitalize">Add</span>
          </button>
        </div>
      </Drawer>
    </>
  );
};

export default DetailsDrawer;
