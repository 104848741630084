import { useRef } from "react";
import { MdPause, MdPlayArrow } from "react-icons/md";
import { RiSettings4Fill } from "react-icons/ri";
import useAudio from "./hooks/useAudio";

export default function AudioPlayer({ src, isOpen }) {
  const audioRef = useRef();
  const indicatorRef = useRef();
  const progressRef = useRef();

  const {
    timer,
    playbackRate,
    isPlaying,
    forwardAudio,
    rewindAudio,
    setIsPlaying,
    changePlaybackSpeed,
    handleProgressBarClick,
  } = useAudio(audioRef, indicatorRef, progressRef);

  if (!isOpen) return;

  return (
    <>
      <audio src={src} ref={audioRef} controls hidden />
      <div className="h-[4px] w-full bg-primary-neutral-200 relative" onClick={handleProgressBarClick}>
        <div className="h-[4px] absolute top-0 left-0 w-0 bg-primary-neutral-800" ref={progressRef}></div>
        <div
          className="h-3 w-3 rounded-full bg-primary-neutral-800 absolute top-1/2 left-0 transform -translate-y-1/2"
          ref={indicatorRef}></div>
      </div>
      <div className="flex items-center justify-between text-primary-neutral-800 font-satoshi font-bold text-xs">
        <span>{timer.start}</span>
        <span>{timer.end}</span>
      </div>
      <div className="flex px-2 justify-between items-center font-satoshi">
        <span
          className="text-primary-neutral-500 text-sm font-bold leading-5 cursor-pointer"
          onClick={changePlaybackSpeed}>
          {playbackRate}x
        </span>
        {/* rewind audio */}
        <div
          className="flex p-2 items-start gap-2 rounded-full border border-primary-neutral-500 cursor-pointer"
          onClick={rewindAudio}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              d="M12.8806 16.9203H10.5906C10.1806 16.9203 9.84062 16.5803 9.84062 16.1703C9.84062 15.7603 10.1806 15.4203 10.5906 15.4203H12.8806C13.3106 15.4203 13.6606 15.0703 13.6606 14.6403C13.6606 14.2103 13.3106 13.8603 12.8806 13.8603H10.5906C10.3506 13.8603 10.1206 13.7403 9.98062 13.5503C9.84062 13.3603 9.80062 13.1003 9.88062 12.8703L10.6406 10.5803C10.7406 10.2703 11.0306 10.0703 11.3506 10.0703H14.4106C14.8206 10.0703 15.1606 10.4103 15.1606 10.8203C15.1606 11.2303 14.8206 11.5703 14.4106 11.5703H11.8906L11.6306 12.3603H12.8806C14.1406 12.3603 15.1606 13.3803 15.1606 14.6403C15.1606 15.9003 14.1406 16.9203 12.8806 16.9203Z"
              fill="#363430"
            />
            <path
              d="M12.4996 3.47945C12.4196 3.47945 12.3396 3.48945 12.2596 3.48945L13.0796 2.46945C13.3396 2.14945 13.2896 1.66945 12.9596 1.41945C12.6296 1.16945 12.1696 1.20945 11.9096 1.53945L9.93961 3.99945C9.92961 4.00945 9.92961 4.01945 9.91961 4.03945C9.88961 4.07945 9.86961 4.12945 9.84961 4.16945C9.82961 4.21945 9.80961 4.25945 9.79961 4.29945C9.78961 4.34945 9.78961 4.38945 9.78961 4.43945V4.58945V4.63945C9.79961 4.66945 9.81961 4.68945 9.82961 4.71945C9.84961 4.76945 9.86961 4.80945 9.88961 4.85945C9.91961 4.89945 9.94961 4.93945 9.98961 4.96945C10.0096 4.99945 10.0196 5.02945 10.0496 5.04945C10.0696 5.05945 10.0796 5.06945 10.0996 5.07945C10.1296 5.09945 10.1496 5.10945 10.1796 5.11945C10.2296 5.14945 10.2896 5.16945 10.3496 5.17945C10.3796 5.19945 10.4096 5.19945 10.4396 5.19945C10.4696 5.19945 10.4896 5.20945 10.5196 5.20945C10.5496 5.20945 10.5696 5.19945 10.5896 5.18945C10.6196 5.18945 10.6496 5.19945 10.6796 5.18945C11.3196 5.03945 11.9196 4.96945 12.4896 4.96945C16.9796 4.96945 20.6296 8.61945 20.6296 13.1095C20.6296 17.5994 16.9796 21.2495 12.4896 21.2495C7.99961 21.2495 4.34961 17.5994 4.34961 13.1095C4.34961 11.3695 4.91961 9.68945 5.99961 8.24945C6.24961 7.91945 6.17961 7.44945 5.84961 7.19945C5.51961 6.94945 5.04961 7.01945 4.79961 7.34945C3.51961 9.04945 2.84961 11.0395 2.84961 13.1095C2.84961 18.4195 7.16961 22.7495 12.4896 22.7495C17.8096 22.7495 22.1296 18.4295 22.1296 13.1095C22.1296 7.78945 17.8096 3.47945 12.4996 3.47945Z"
              fill="#363430"
            />
          </svg>
        </div>
        {/* pause / play */}
        <div
          className="flex p-4 gap-2 items-start rounded-full bg-primary-neutral-800 cursor-pointer"
          onClick={() => setIsPlaying(!isPlaying)}>
          {isPlaying ? <MdPause size={32} color="white" /> : <MdPlayArrow size={32} color="white" />}
        </div>
        {/* forward audio */}
        <div
          className="flex p-2 items-start gap-2 rounded-full border border-primary-neutral-500 cursor-pointer"
          onClick={forwardAudio}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              d="M14.4797 5.21944C14.2597 5.21944 14.0398 5.11947 13.8998 4.93947L11.9197 2.46944C11.6597 2.14944 11.7097 1.66945 12.0397 1.41945C12.3697 1.16945 12.8298 1.20945 13.0898 1.53945L15.0698 4.00948C15.3298 4.32948 15.2798 4.80947 14.9498 5.05947C14.8098 5.15947 14.6397 5.21944 14.4797 5.21944Z"
              fill="#363430"
            />
            <path
              d="M12.4994 22.7488C7.18939 22.7488 2.85938 18.4288 2.85938 13.1088C2.85938 7.78877 7.17939 3.46875 12.4994 3.46875C13.1894 3.46875 13.8894 3.54876 14.6494 3.72876C15.0494 3.81876 15.3094 4.22878 15.2094 4.62878C15.1194 5.02878 14.7194 5.28878 14.3094 5.18878C13.6694 5.03878 13.0694 4.96875 12.4994 4.96875C8.00939 4.96875 4.35938 8.61877 4.35938 13.1088C4.35938 17.5988 8.00939 21.2488 12.4994 21.2488C16.9894 21.2488 20.6394 17.5988 20.6394 13.1088C20.6394 11.3688 20.0694 9.68878 18.9894 8.24878C18.7394 7.91878 18.8094 7.44879 19.1394 7.19879C19.4694 6.94879 19.9394 7.01876 20.1894 7.34876C21.4694 9.04876 22.1394 11.0388 22.1394 13.1088C22.1394 18.4288 17.8094 22.7488 12.4994 22.7488Z"
              fill="#363430"
            />
            <path
              d="M12.8806 16.9202H10.5907C10.1806 16.9202 9.84065 16.5802 9.84065 16.1702C9.84065 15.7602 10.1806 15.4202 10.5907 15.4202H12.8806C13.3106 15.4202 13.6606 15.0703 13.6606 14.6403C13.6606 14.2103 13.3106 13.8602 12.8806 13.8602H10.5907C10.3506 13.8602 10.1206 13.7402 9.9806 13.5502C9.8406 13.3602 9.80063 13.1003 9.88063 12.8703L10.6406 10.5803C10.7406 10.2703 11.0306 10.0703 11.3506 10.0703H14.4106C14.8206 10.0703 15.1606 10.4103 15.1606 10.8203C15.1606 11.2303 14.8206 11.5703 14.4106 11.5703H11.8906L11.6306 12.3602H12.8806C14.1406 12.3602 15.1606 13.3803 15.1606 14.6403C15.1606 15.9003 14.1406 16.9202 12.8806 16.9202Z"
              fill="#363430"
            />
          </svg>
        </div>
        <RiSettings4Fill color="#696763" size={24} />
      </div>
    </>
  );
}
