import { useEffect, useState } from "react";

export default function useAudio(audioRef, indicatorRef, progressRef) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [timer, setTimer] = useState({
    start: "00:00",
    end: "00:00",
  });
  const [playbackRate, setPlaybackRate] = useState(1);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  const forwardAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.min(audioRef.current?.currentTime + 5, audioRef.current?.duration);
    }
  };

  const rewindAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = Math.max(audioRef.current?.currentTime - 5, 0);
    }
  };

  const changePlaybackSpeed = () => {
    const speeds = [0.5, 1, 1.5, 2];
    const currentSpeedIndex = speeds.indexOf(playbackRate);
    const nextSpeed = speeds[(currentSpeedIndex + 1) % speeds.length];
    setPlaybackRate(nextSpeed);
    audioRef.current.playbackRate = nextSpeed;
  };

  const handleProgressBarClick = (e) => {
    const progressBar = e.currentTarget;
    const clickX = e.nativeEvent.offsetX;
    const progressBarWidth = progressBar.offsetWidth;
    const clickRatio = clickX / progressBarWidth;
    const newTime = clickRatio * audioRef.current?.duration;
    audioRef.current.currentTime = newTime;
  };

  useEffect(() => {
    if (audioRef.current) {
      // Set the end time when the audio metadata is loaded
      audioRef.current.onloadedmetadata = () => {
        setTimer((prevTimer) => ({
          ...prevTimer,
          end: formatTime(audioRef.current?.duration),
        }));
      };
    }
  }, [audioRef]);

  useEffect(() => {
    if (!audioRef.current) return;
    if (isPlaying) {
      audioRef.current.play();

      const intervalId = setInterval(() => {
        if (!audioRef?.current) return clearInterval(intervalId);
        const currentTime = audioRef?.current?.currentTime;
        const duration = audioRef?.current?.duration;

        setTimer((prevTimer) => ({
          ...prevTimer,
          start: formatTime(currentTime),
        }));

        if (progressRef.current && indicatorRef.current && duration) {
          progressRef.current.style.width = `${(currentTime / duration) * 100}%`;
          indicatorRef.current.style.left = `${(currentTime / duration) * 100}%`;
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        audioRef?.current?.pause();
      };
    } else {
      audioRef?.current?.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, audioRef]);

  return {
    timer,
    playbackRate,
    isPlaying,
    forwardAudio,
    rewindAudio,
    setIsPlaying,
    changePlaybackSpeed,
    handleProgressBarClick,
  };
}
