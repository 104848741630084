import PropTypes, { string } from "prop-types";
import { motion } from "framer-motion";
import Content from "./content";
import DetailsDrawer from "./drawer";
import { dropIn, getIcon, shareOnWhatsApp } from "./helper";
import { MdMoreVert } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa6";
import { ReactComponent as ShareIcon } from "assets/svg/share.svg";
import { ReactComponent as Stars } from "assets/svg/stars.svg";
import PreFooter from "components/footer";
import SearchBar from "./searchBar";
import Congrats from "./congrats";
import Lottie from "react-lottie-player";
import animationData from "assets/animation/loading.json";
import useDetails from "./hooks/useDetails";
import Navigation from "./navigation";

const Line = () => {
  return (
    <div className="flex pl-10 items-start self-stretch">
      <div className="h-[2px] w-full bg-primary-neutral-100"></div>
    </div>
  );
};

export default function DetailsScreen() {
  const {
    selectedCategory,
    title,
    category,
    search,
    addedContents,
    contents,
    loading,
    drawer,
    isPrivate,
    isCongratsOpen,
    isPreviewMode,
    divRef,
    user,
    isCreateFlow,
    handleSwipeRight,
    handleSwipeLeft,
    setCurrentIndex,
    setIsPrivate,
    setDrawer,
    setCongratsOpen,
    setIsPreviewMode,
    setSearch,
    handleAdd,
    isAdded,
    handleSearch,
  } = useDetails();

  const isLoggedIn = user !== null;

  return (
    <>
      <DetailsDrawer
        {...drawer}
        toggleDrawer={() =>
          setDrawer({
            ...drawer,
            isOpen: !drawer.isOpen,
            videoId: "",
          })
        }
        handleSwipeRight={handleSwipeRight}
        handleSwipeLeft={handleSwipeLeft}
      />

      {isCreateFlow && <Congrats isOpen={isCongratsOpen} toggleDrawer={() => setCongratsOpen((prev) => !prev)} />}

      {loading && (
        <div className="fixed z-[60] h-[100svh] w-screen top-0 text-white font-bold bg-black/70 flex flex-col justify-center items-center">
          <div className="h-14 w-14 relative">
            <Lottie
              loop
              animationData={animationData}
              play
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
          <span className="tracking-[0.6px]">Building Your Vibe...</span>
        </div>
      )}

      <motion.div
        className="z-10 max-w-[100vw] w-full  h-full min-h-[calc(100svh-60px)] bg-primary-neutral-50"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit">
        <section ref={divRef} className="bg-primary-neutral-50 animate-popup h-full flex flex-col justify-between">
          <div>
            <div
              className="sticky top-[60px] bg-white z-10 border-t border-t-primary-neutral-200 scroll-m-[200px] shadow"
              id="top">
              <div className="flex w-full items-center gap-6 px-6 py-4 self-stretch">
                <img
                  src={selectedCategory?.image ?? "/assets/images/card.png"}
                  className="h-12 w-12 object-contain rounded-full"
                  alt={title}
                />
                <div className="flex flex-col flex-[1_0_0]">
                  <p className="font-lato text-[18px] tracking-[0.72px] leading-7 font-bold text-primary-neutral-800">
                    {title}
                  </p>
                  <p className="font-satoshi text-[12px] tracking-[0.48px] leading-5 font-normal text-primary-neutral-500">
                    {category} (theme)
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  {!isCreateFlow && (
                    <ShareIcon className="cursor-pointer" onClick={() => shareOnWhatsApp(title, window.location)} />
                  )}
                  {!isPreviewMode ? (
                    isLoggedIn ? (
                      <MdMoreVert size={24} color="#363430" />
                    ) : null
                  ) : isPrivate ? (
                    <div className="flex flex-col items-center justify-center gap-0.5">
                      <FaRegEyeSlash color="#696763" size={16} />
                      <span className="text-primary-neutral-500 font-satoshi text-2xs font-medium leading-4 tracking-[0.4px]">
                        Private
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="md:px-[120px] py-6 bg-primary-neutral-50">
              <div className="flex p-4 flex-col items-start gap-10 pb-[100px]">
                {isCreateFlow && !isPreviewMode && (
                  <div className="flex justify-between w-full">
                    <div className="flex items-center gap-2">
                      <span className="font-satoshi font-bold text-xs text-primary-neutral-800 leading-5 flex gap-2">
                        Make Private:
                      </span>
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={isPrivate}
                          onChange={(e) => setIsPrivate(e.target.checked)}
                          className="sr-only peer"
                        />
                        <div className="relative w-7 h-4 bg-primary-neutral-400 peer-focus:outline-none peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-[#2BB656]"></div>
                      </label>
                    </div>
                    <p
                      className="underline underline-offset-2 text-[#75DD67] cursor-pointer"
                      onClick={() => setIsPreviewMode(true)}>
                      <span className="font-satoshi text-xs text-[#363430] font-bold leading-5 underline gradient">
                        Preview ({addedContents.length ?? 0})
                      </span>
                    </p>
                  </div>
                )}
                {!isPreviewMode && (
                  <p className="font-satoshi font-medium text-xs text-primary-neutral-800 leading-5 flex gap-2">
                    <Stars className="h-5 w-5" />
                    Suggested content based on the {title}
                    ...
                  </p>
                )}
                {!isPreviewMode ? (
                  <div className="flex flex-col gap-[20px]">
                    {contents.map((content, i) => {
                      return content?.data?.map((d, j) => {
                        return (
                          <>
                            <Content
                              showAddButton={isPreviewMode || !isCreateFlow}
                              id={`${i}-${j}`}
                              key={`${content.type}-${i}-${j}`}
                              icon={getIcon(content.type)}
                              title={d.response ?? d.title}
                              isAdded={isAdded(content.type, d)}
                              onAdd={() => handleAdd(content, d)}
                              onClick={() => {
                                setCurrentIndex({
                                  type: content.type,
                                  index: j,
                                });
                                setDrawer({
                                  isOpen: true,
                                  text: d.response ?? d.title,
                                  category: category,
                                  title,
                                  type: content.type,
                                  disabledPrev: i === 0,
                                });
                              }}
                            />
                            <Line />
                          </>
                        );
                      });
                    })}
                  </div>
                ) : (
                  <div className="flex flex-col gap-[20px]">
                    {addedContents.map((content, i) => {
                      return (
                        <>
                          <Content
                            id={`${i}`}
                            isPreviewMode={isPreviewMode}
                            key={`${content.type}-${i}`}
                            icon={getIcon(content.type)}
                            title={content.response ?? content.title}
                            onClick={() => {
                              setCurrentIndex({
                                type: content.type,
                                index: i,
                              });
                              setDrawer({
                                isOpen: true,
                                text: content.response ?? content.title,
                                category: category,
                                title,
                                type: content.type,
                                disabledPrev: i === 0,
                              });
                            }}
                          />
                          <Line />
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          {!isCreateFlow && contents.length > 0 && <PreFooter />}
          {isPreviewMode && (
            <Navigation handleNext={() => setCongratsOpen(true)} handleBack={() => setIsPreviewMode(false)} />
          )}
        </section>
      </motion.div>
      {isCreateFlow && !isPreviewMode && <SearchBar search={search} setSearch={setSearch} onSearch={handleSearch} />}
    </>
  );
}

DetailsScreen.propTypes = {
  onBack: PropTypes.func,
  keywords: PropTypes.arrayOf(string),
  onKeywordChange: PropTypes.func,
  data: PropTypes.object,
  setData: PropTypes.func,
};
