const { generateImage } = require("config/APIs/aws");

async function getImage(prompt) {
    try {
        let res = await generateImage(prompt);

        if (res?.status) {
            return res?.data?.s3_url;
        }
    } catch (e) {
        console.log(e);

        return "";
    }
}

export { getImage };
