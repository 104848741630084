export const defaultCategories = [
  {
    category: "Science & Technology",
    data: [
      {
        slug: "artificial-intelligence",
        title: "Artificial Intelligence",
        alias:
          "Artificial Intelligence (AI) is the simulation of human intelligence in machines, enabling them to perform tasks that typically require human cognition.",
        category: "Science & Technology",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/8140457604477051239.png",
      },
      {
        slug: "quantum-computing",
        title: "Quantum Computing",
        alias:
          "Quantum Computing leverages the principles of quantum mechanics to perform computations at speeds far beyond traditional computers.",
        category: "Science & Technology",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/4949480976387789135.png",
      },
      {
        slug: "space-exploration",
        title: "Space Exploration",
        alias:
          "Space Exploration is the investigation of outer space through the use of astronomy, spacecraft, and related technologies.",
        category: "Science & Technology",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-5424498804704844911.png",
      },
      {
        slug: "biotechnology",
        title: "Biotechnology",
        alias:
          "Biotechnology involves using living organisms or systems to develop or create products, often in fields like medicine and agriculture.",
        category: "Science & Technology",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-2493287040626972301.png",
      },
    ],
  },
  {
    category: "Health & Wellness",
    data: [
      {
        slug: "mental-health",
        title: "Mental Health",
        alias:
          "Mental Health refers to the state of well-being in which an individual realizes their potential, can cope with normal stresses, and can work productively.",
        category: "Health & Wellness",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-8694455218036820739.png",
      },
      {
        slug: "nutrition",
        title: "Nutrition",
        alias:
          "Nutrition is the science of how food affects the health and growth of living organisms, focusing on diet and dietary needs.",
        category: "Health & Wellness",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/1552966920801173647.png",
      },
      {
        slug: "exercise-fitness",
        title: "Exercise & Fitness",
        alias:
          "Exercise & Fitness encompass physical activities that improve or maintain physical health and overall well-being.",
        category: "Health & Wellness",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-5000979418086270423.png",
      },
      {
        slug: "medical-research",
        title: "Medical Research",
        alias:
          "Medical Research is the scientific study of health, disease, and medicine, aimed at improving knowledge and treatment outcomes.",
        category: "Health & Wellness",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/1583295469826194126.png",
      },
    ],
  },
  {
    category: "History & Culture",
    data: [
      {
        slug: "ancient-civilizations",
        title: "Ancient Civilizations",
        alias:
          "Ancient Civilizations refers to societies that existed in the distant past, known for their cultural, technological, and architectural advancements.",
        category: "History & Culture",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-2940870569686775621.png",
      },
      {
        slug: "world-wars",
        title: "World Wars",
        alias:
          "World Wars are the two major global conflicts in the 20th century that significantly shaped modern history and international relations.",
        category: "History & Culture",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/714744752191229811.png",
      },
      {
        slug: "cultural-anthropology",
        title: "Cultural Anthropology",
        alias: "Cultural Anthropology is the study of human societies and cultures and their development.",
        category: "History & Culture",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-7865663447614599652.png",
      },
      {
        slug: "art-history",
        title: "Art History",
        alias:
          "Art History is the study of visual arts and their historical development, including movements, artists, and cultural influences.",
        category: "History & Culture",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-2729142144046903175.png",
      },
    ],
  },
  {
    category: "Business & Finance",
    data: [
      {
        slug: "stock-market",
        title: "Stock Market",
        alias:
          "The Stock Market is a public market for buying and selling company shares and derivatives at agreed prices.",
        category: "Business & Finance",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/4144969156581404565.png",
      },
      {
        slug: "entrepreneurship",
        title: "Entrepreneurship",
        alias:
          "Entrepreneurship involves the process of designing, launching, and running a new business, often starting with an innovative idea.",
        category: "Business & Finance",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-6683720979451518549.png",
      },
      {
        slug: "cryptocurrency",
        title: "Cryptocurrency",
        alias:
          "Cryptocurrency is a digital or virtual currency that uses cryptography for security and operates independently of a central bank.",
        category: "Business & Finance",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-2764472176272489206.png",
      },
      {
        slug: "economics",
        title: "Economics",
        alias:
          "Economics is the social science that studies the production, distribution, and consumption of goods and services.",
        category: "Business & Finance",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/4806859490957474422.png",
      },
    ],
  },
  {
    category: "Environmental Science",
    data: [
      {
        slug: "climate-change",
        title: "Climate Change",
        alias:
          "Climate Change refers to long-term alterations in temperature, precipitation, and other atmospheric conditions on Earth.",
        category: "Environmental Science",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/5876648091624284824.png",
      },
      {
        slug: "sustainability",
        title: "Sustainability",
        alias:
          "Sustainability is the practice of using resources in a way that maintains ecological balance and does not deplete them for future generations.",
        category: "Environmental Science",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-2666959520241657288.png",
      },
      {
        slug: "renewable-energy",
        title: "Renewable Energy",
        alias:
          "Renewable Energy is energy generated from natural sources that are continuously replenished, such as wind, solar, and hydro power.",
        category: "Environmental Science",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-1635455222892438524.png",
      },
      {
        slug: "biodiversity",
        title: "Biodiversity",
        alias:
          "Biodiversity refers to the variety of life in all its forms, including species, ecosystems, and genetic diversity.",
        category: "Environmental Science",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/2650434351054297996.png",
      },
    ],
  },
  {
    category: "Education & Learning",
    data: [
      {
        slug: "pedagogy",
        title: "Pedagogy",
        alias: "Pedagogy is the art or science of teaching and educational methods.",
        category: "Education & Learning",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/8366146997369893102.png",
      },
      {
        slug: "online-learning",
        title: "Online Learning",
        alias:
          "Online Learning refers to education that takes place over the internet, allowing for remote and flexible study.",
        category: "Education & Learning",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/9213117423183645813.png",
      },
      {
        slug: "stem-education",
        title: "STEM Education",
        alias:
          "STEM Education is an approach to learning that integrates science, technology, engineering, and mathematics.",
        category: "Education & Learning",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/8319192336397422935.png",
      },
      {
        slug: "cognitive-science",
        title: "Cognitive Science",
        alias:
          "Cognitive Science is the interdisciplinary study of the mind and its processes, including how people think, learn, and remember.",
        category: "Education & Learning",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/6473271471257271104.png",
      },
    ],
  },
  {
    category: "Literature & Philosophy",
    data: [
      {
        slug: "classical-literature",
        title: "Classical Literature",
        alias:
          "Classical Literature refers to the great literary works of ancient Greece and Rome, and other cultures, that have stood the test of time.",
        category: "Literature & Philosophy",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/838594150657922770.png",
      },
      {
        slug: "philosophical-theories",
        title: "Philosophical Theories",
        alias:
          "Philosophical Theories are frameworks of thought that explore fundamental questions about reality, existence, knowledge, and ethics.",
        category: "Literature & Philosophy",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/6786324585989326802.png",
      },
      {
        slug: "modern-fiction",
        title: "Modern Fiction",
        alias:
          "Modern Fiction is literature written in the 20th and 21st centuries, often characterized by a break from traditional forms and themes.",
        category: "Literature & Philosophy",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-4898104977250629668.png",
      },
      {
        slug: "poetry",
        title: "Poetry",
        alias:
          "Poetry is a literary form that uses rhythmic and aesthetic qualities of language to evoke meanings beyond the prose's literal interpretation.",
        category: "Literature & Philosophy",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-912443949421622659.png",
      },
    ],
  },
  {
    category: "Politics & Governance",
    data: [
      {
        slug: "international-relations",
        title: "International Relations",
        alias:
          "International Relations is the study of the interactions between nations, including diplomacy, conflict, trade, and cooperation.",
        category: "Politics & Governance",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/2298159082542853357.png",
      },
      {
        slug: "public-policy",
        title: "Public Policy",
        alias:
          "Public Policy refers to the system of laws, regulations, actions, and funding priorities set by governmental entities.",
        category: "Politics & Governance",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/4548177597967728323.png",
      },
      {
        slug: "political-philosophy",
        title: "Political Philosophy",
        alias:
          "Political Philosophy explores ideas about government, justice, rights, liberty, and the role of individuals in society.",
        category: "Politics & Governance",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/6993668865561254120.png",
      },
      {
        slug: "elections",
        title: "Elections",
        alias:
          "Elections are formal processes in which voters select candidates or decide on policy issues through a vote.",
        category: "Politics & Governance",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-5633450475749195636.png",
      },
    ],
  },
  {
    category: "Sports & Recreation",
    data: [
      {
        slug: "team-sports",
        title: "Team Sports",
        alias:
          "Team Sports involve organized games where individuals play together in groups to achieve a common goal.",
        category: "Sports & Recreation",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/8044364633117728586.png",
      },
      {
        slug: "individual-sports",
        title: "Individual Sports",
        alias:
          "Individual Sports are athletic competitions where participants compete as individuals rather than as part of a team.",
        category: "Sports & Recreation",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/4096051095044409422.png",
      },
      {
        slug: "outdoor-activities",
        title: "Outdoor Activities",
        alias:
          "Outdoor Activities encompass recreational pursuits conducted in natural environments, such as hiking, camping, and fishing.",
        category: "Sports & Recreation",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-7757461789677210001.png",
      },
      {
        slug: "esports",
        title: "eSports",
        alias:
          "eSports refers to competitive video gaming, where players compete in organized tournaments, often for significant prizes.",
        category: "Sports & Recreation",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/824168435031691086.png",
      },
    ],
  },
  {
    category: "Entertainment & Media",
    data: [
      {
        slug: "film-television",
        title: "Film & Television",
        alias:
          "Film & Television are visual media forms that entertain, inform, and inspire audiences through storytelling and visual creativity.",
        category: "Entertainment & Media",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-4781003502573624223.png",
      },
      {
        slug: "music",
        title: "Music",
        alias:
          "Music is the art of combining vocal or instrumental sounds to produce harmony, expression, and emotional effect.",
        category: "Entertainment & Media",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/7114606007058118017.png",
      },
      {
        slug: "theatre-performing-arts",
        title: "Theatre & Performing Arts",
        alias:
          "Theatre & Performing Arts involve live performances of plays, dance, and music, often intended to entertain and provoke thought.",
        category: "Entertainment & Media",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/999814437774777283.png",
      },
      {
        slug: "video-games",
        title: "Video Games",
        alias:
          "Video Games are electronic games played on a computer or console, involving interaction with a user interface to generate visual feedback.",
        category: "Entertainment & Media",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-2557962046948517243.png",
      },
    ],
  },
  {
    category: "Travel & Tourism",
    data: [
      {
        slug: "world-destinations",
        title: "World Destinations",
        alias:
          "World Destinations refer to popular locations around the globe that attract travelers for their culture, history, and natural beauty.",
        category: "Travel & Tourism",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/8846362344980860996.png",
      },
      {
        slug: "cultural-experiences",
        title: "Cultural Experiences",
        alias:
          "Cultural Experiences involve engaging with the customs, traditions, and lifestyle of people from different backgrounds.",
        category: "Travel & Tourism",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-8805265752909406448.png",
      },
      {
        slug: "adventure-travel",
        title: "Adventure Travel",
        alias:
          "Adventure Travel is a type of tourism involving exploration or travel with a certain degree of risk, often in remote locations.",
        category: "Travel & Tourism",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/2652088516876620283.png",
      },
      {
        slug: "travel-planning",
        title: "Travel Planning",
        alias:
          "Travel Planning involves organizing and arranging the logistics of a trip, including accommodations, transportation, and activities.",
        category: "Travel & Tourism",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-8048742602056225889.png",
      },
    ],
  },
  {
    category: "Food & Culinary Arts",
    data: [
      {
        slug: "world-cuisines",
        title: "World Cuisines",
        alias:
          "World Cuisines refer to the diverse culinary traditions and practices from different cultures and regions around the globe.",
        category: "Food & Culinary Arts",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/8639174269179598079.png",
      },
      {
        slug: "cooking-techniques",
        title: "Cooking Techniques",
        alias:
          "Cooking Techniques are methods or processes used in the preparation of food, often varying by culture and cuisine.",
        category: "Food & Culinary Arts",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-8635052709249309355.png",
      },
      {
        slug: "food-history",
        title: "Food History",
        alias:
          "Food History explores the origins and evolution of food, ingredients, and culinary practices throughout human history.",
        category: "Food & Culinary Arts",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-6015134351532885241.png",
      },
      {
        slug: "beverages",
        title: "Beverages",
        alias:
          "Beverages encompass a wide range of drinks, including non-alcoholic and alcoholic, consumed for refreshment or pleasure.",
        category: "Food & Culinary Arts",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/2875061850022149227.png",
      },
    ],
  },
  {
    category: "Psychology & Behavior",
    data: [
      {
        slug: "cognitive-psychology",
        title: "Cognitive Psychology",
        alias:
          "Cognitive Psychology studies mental processes such as perception, memory, problem-solving, and decision-making.",
        category: "Psychology & Behavior",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/4832750481652501954.png",
      },
      {
        slug: "social-psychology",
        title: "Social Psychology",
        alias:
          "Social Psychology examines how people's thoughts, feelings, and behaviors are influenced by the actual or imagined presence of others.",
        category: "Psychology & Behavior",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-6993181037082464076.png",
      },
      {
        slug: "personality-studies",
        title: "Personality Studies",
        alias:
          "Personality Studies explore the patterns of thoughts, feelings, and behaviors that make a person unique.",
        category: "Psychology & Behavior",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-1119195715360631279.png",
      },
      {
        slug: "behavioral-economics",
        title: "Behavioral Economics",
        alias: "Behavioral Economics combines psychology and economics to study how people make economic decisions.",
        category: "Psychology & Behavior",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-6715746845129772713.png",
      },
    ],
  },
  {
    category: "Law & Ethics",
    data: [
      {
        title: "Criminal Law",
        alias: "Criminal Law involves the system of laws concerned with punishing individuals who commit crimes.",
        category: "Law & Ethics",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/569345614823871211.png",
        slug: "criminal-law",
      },
      {
        title: "Civil Rights",
        alias:
          "Civil Rights are the rights of individuals to receive equal treatment and be free from discrimination in various settings.",
        category: "Law & Ethics",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/8720770679366410235.png",
        slug: "civil-rights",
      },
      {
        title: "Ethical Theories",
        alias:
          "Ethical Theories are frameworks for understanding and analyzing moral issues, guiding the differentiation between right and wrong.",
        category: "Law & Ethics",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-4786353013854487088.png",
        slug: "ethical-theories",
      },
      {
        title: "Corporate Law",
        alias:
          "Corporate Law deals with the formation, operation, and regulation of corporations and their legal rights and obligations.",
        category: "Law & Ethics",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/2335961660901175591.png",
        slug: "corporate-law",
      },
    ],
  },
  {
    category: "Art & Design",
    data: [
      {
        title: "Visual Arts",
        alias:
          "Visual Arts include art forms that are primarily visual in nature, such as painting, sculpture, and photography.",
        category: "Art & Design",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/5705050653705484138.png",
        slug: "visual-arts",
      },
      {
        title: "Graphic Design",
        alias:
          "Graphic Design is the art of creating visual content to communicate messages using typography, imagery, and layout.",
        category: "Art & Design",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-291914794538202192.png",
        slug: "graphic-design",
      },
      {
        title: "Architecture",
        alias:
          "Architecture is the art and science of designing and constructing buildings and other physical structures.",
        category: "Art & Design",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-6330589373473895894.png",
        slug: "architecture",
      },
      {
        title: "Fashion Design",
        alias:
          "Fashion Design is the art of applying design, aesthetics, and natural beauty to clothing and accessories.",
        category: "Art & Design",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-8404194936124002924.png",
        slug: "fashion-design",
      },
    ],
  },
  {
    category: "Languages & Linguistics",
    data: [
      {
        title: "Language Learning",
        alias:
          "Language Learning involves acquiring the ability to understand, speak, read, and write in a language other than one's native tongue.",
        category: "Languages & Linguistics",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-3052990486452097807.png",
        slug: "language-learning",
      },
      {
        title: "Sociolinguistics",
        alias:
          "Sociolinguistics studies the relationship between language and society, including language variation and social functions.",
        category: "Languages & Linguistics",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-6461885154196930166.png",
        slug: "sociolinguistics",
      },
      {
        title: "Historical Linguistics",
        alias:
          "Historical Linguistics examines the history and development of languages over time, including language change and language families.",
        category: "Languages & Linguistics",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/904144326413227253.png",
        slug: "historical-linguistics",
      },
      {
        title: "Translation Studies",
        alias:
          "Translation Studies explore the process of translating text from one language to another, including theoretical and practical aspects.",
        category: "Languages & Linguistics",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-5550103837466361299.png",
        slug: "translation-studies",
      },
    ],
  },
  {
    category: "Religion & Spirituality",
    data: [
      {
        title: "World Religions",
        alias:
          "World Religions encompass the various belief systems and practices followed by different cultures and societies around the globe.",
        category: "Religion & Spirituality",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-5862179581019192623.png",
        slug: "world-religions",
      },
      {
        title: "Spiritual Practices",
        alias:
          "Spiritual Practices include rituals, meditation, and other activities aimed at enhancing spiritual awareness and connection.",
        category: "Religion & Spirituality",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/7989145254195304887.png",
        slug: "spiritual-practices",
      },
      {
        title: "Philosophy of Religion",
        alias:
          "Philosophy of Religion is the philosophical study of religious beliefs, practices, and the nature of the divine.",
        category: "Religion & Spirituality",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-6168548101842497856.png",
        slug: "philosophy-of-religion",
      },
      {
        title: "Mythology",
        alias:
          "Mythology is the study of myths, traditional stories that explain natural or social phenomena and often involve supernatural beings or events.",
        category: "Religion & Spirituality",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-3334914028124418114.png",
        slug: "mythology",
      },
    ],
  },
  {
    category: "Personal Development",
    data: [
      {
        title: "Mindfulness",
        alias:
          "Mindfulness is the practice of focusing one's attention on the present moment while calmly acknowledging and accepting feelings, thoughts, and bodily sensations.",
        category: "Personal Development",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/4192750664742579864.png",
        slug: "mindfulness",
      },
      {
        title: "Time Management",
        alias:
          "Time Management involves organizing and planning how to divide time between specific activities to increase efficiency and productivity.",
        category: "Personal Development",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-1281448279008507592.png",
        slug: "time-management",
      },
      {
        title: "Leadership",
        alias:
          "Leadership is the ability to guide, influence, or direct a group of people toward achieving a common goal.",
        category: "Personal Development",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-1100779776921865848.png",
        slug: "leadership",
      },
      {
        title: "Emotional Intelligence",
        alias:
          "Emotional Intelligence is the ability to recognize, understand, and manage one's own emotions and the emotions of others.",
        category: "Personal Development",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-3633268405195302761.png",
        slug: "emotional-intelligence",
      },
    ],
  },
  {
    category: "Technology & Gadgets",
    data: [
      {
        title: "Consumer Electronics",
        alias:
          "Consumer Electronics are electronic devices intended for everyday use, typically in entertainment, communication, and home office activities.",
        category: "Technology & Gadgets",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-9096183009851038943.png",
        slug: "consumer-electronics",
      },
      {
        title: "Smart Home",
        alias:
          "Smart Home refers to a residence equipped with devices that can be controlled remotely by a phone or computer, often involving automation and internet connectivity.",
        category: "Technology & Gadgets",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-2543818359729473103.png",
        slug: "smart-home",
      },
      {
        title: "Wearable Tech",
        alias:
          "Wearable Tech includes smart devices worn on the body, such as smartwatches and fitness trackers, that often monitor health and activity.",
        category: "Technology & Gadgets",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/8341863400982199584.png",
        slug: "wearable-tech",
      },
      {
        title: "Tech Reviews",
        alias:
          "Tech Reviews are evaluations of the latest gadgets and technology products, often providing insights into performance, usability, and value.",
        category: "Technology & Gadgets",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-6435641682213589868.png",
        slug: "tech-reviews",
      },
    ],
  },
  {
    category: "Engineering & Innovation",
    data: [
      {
        title: "Mechanical Engineering",
        alias:
          "Mechanical Engineering is the branch of engineering that involves the design, production, and operation of machinery.",
        category: "Engineering & Innovation",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/1928130894130929180.png",
        slug: "mechanical-engineering",
      },
      {
        title: "Robotics",
        alias:
          "Robotics is the interdisciplinary branch of engineering and science that involves the design, construction, operation, and use of robots.",
        category: "Engineering & Innovation",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-2220955951294726385.png",
        slug: "robotics",
      },
      {
        title: "Civil Engineering",
        alias:
          "Civil Engineering is the discipline of engineering that deals with the design, construction, and maintenance of the physical and naturally built environment.",
        category: "Engineering & Innovation",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/-5398271275460304086.png",
        slug: "civil-engineering",
      },
      {
        title: "Innovation Management",
        alias:
          "Innovation Management is the process of managing the development of new ideas, products, or processes within an organization.",
        category: "Engineering & Innovation",
        image: "https://chronos-caarya.s3.amazonaws.com/caarya-constructor/6711255251264302669.png",
        slug: "innovation-management",
      },
    ],
  },
];

export const findBySlug = (slug) => {
  const flattenedData = defaultCategories.flatMap((category) => category.data);

  const data = flattenedData.find((item) => item.slug === slug);

  if (data) {
    return {
      ...data,
    };
  } else {
    return null;
  }
};
