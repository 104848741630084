import { RiHeadphoneFill } from "react-icons/ri";
import { IoIosPlayCircle } from "react-icons/io";
import { IoReader } from "react-icons/io5";

export const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.5,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
};

export const colors = {
    orange: {
        gradientLight: "linear-gradient(180deg, #FFF 0%, #FFF1EB 100%)",
        gradient: "linear-gradient(180deg, #FFF1EB 0%, #FEC5A4 100%)",
        color: "#F57D34",
    },
    green: {
        gradientLight: "linear-gradient(180deg, #FFF 0%, #E9FCE8 100%)",
        gradient: "linear-gradient(180deg, #E9FCE8 0%, #C2F2BD 100%)",
        color: "#2BB656",
    },
    blue: {
        gradientLight: "linear-gradient(180deg, #FFF 0%, #F5FBFF 100%)",
        gradient: "linear-gradient(180deg, #F5FBFF 0%, #C8E3F9 100%)",
        color: "#3996E3",
    },
    purple: {
        gradientLight: "linear-gradient(180deg, #FFF 0%, #F7F7FF 100%)",
        gradient: "linear-gradient(180deg, #F7F7FF 0%, #D8D7F9 100%)",
        color: "#816FE9",
    },
    yellow: {
        gradientLight: "linear-gradient(180deg, #FFF 0%, #FFFCF5 100%)",
        gradient: "linear-gradient(180deg, #FFFCF5 0%, #FDEECD 100%)",
        color: "#EEA638",
    },
};

export const getRandom = () => {
    const max = Object.keys(colors).length;
    const randomIdx = Math.floor(Math.random() * (max - 1));

    return colors[Object.keys(colors)[randomIdx]];
};

export const getIcon = (type) => {
    switch (type) {
        case "language_model":
            return <IoReader size={20} />;
        case "video":
            return <IoIosPlayCircle size={20} />;
        case "podcast":
            return <RiHeadphoneFill size={20} />;
        default:
            return <IoIosPlayCircle size={20} />;
    }
};

export const shareOnWhatsApp = (title, link) => {
    const message = `Checkout my vibe on ${title} ✨ \n \n`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        message
    )}%20${encodeURIComponent(link)}`;

    window.open(whatsappUrl, "_blank");
};
