export const categories = [
  {
    category: "Music",
    background:
      "linear-gradient(180deg, rgba(79, 87, 183, 1) 10%, #0F1317 101%)", // Moderate bright blue
    imageUrl: "/assets/music.png",
  },
  {
    category: "Movies",
    background:
      "linear-gradient(180deg, rgba(206, 85, 17, 1) 0%, #17140F 100%)", // Moderate bright brown
    imageUrl: "/assets/movies.png",
  },
  {
    category: "Standups",
    background:
      "linear-gradient(180deg, rgba(237, 76, 65, 1) 0%, #14141C 100%)", // Moderate bright blue
    imageUrl: "/assets/standups.png",
  },
  {
    category: "Recipies",
    background: "linear-gradient(180deg, rgba(21, 74, 7, 1) 0%, #120B0E 100%)", // Moderate bright red
    imageUrl: "/assets/recipies.png",
  },
  {
    category: "Travel",
    background:
      "linear-gradient(180deg, rgba(144, 55, 149, 1) 0%, #121A18 100%)", // Moderate bright green
    imageUrl: "/assets/travel.png",
  },
  {
    category: "Self Help",
    background:
      "linear-gradient(180deg, rgba(4, 151, 174, 1) 0%, rgba(18, 26, 24, 1) 100%)", // Moderate bright red
    imageUrl: "/assets/selfhelp.png",
  },
  {
    category: "Gaming",
    background:
      "linear-gradient(180deg, rgba(178, 47, 91, 1) 0%, rgba(178, 47, 91, 1) 100%)", // Moderate bright red
    imageUrl: "/assets/gamin.png",
  },
  {
    category: "Fitness",
    background:
      "linear-gradient(180deg, rgba(229, 184, 55, 1) 0%, #0F0909 100%)", // Moderate bright red
    imageUrl: "/assets/fitness.png",
  },
  {
    category: "Anime",
    background:
      "linear-gradient(180deg, rgba(30, 121, 195, 1) 0%, #0F0909 100%)", // Moderate bright red
    imageUrl: "/assets/anime.png",
  },
  {
    category: "Quick Read",
    background:
      "linear-gradient(180deg, rgba(245, 125, 52, 1) 0%, #0F0909 100%)", // Moderate bright red
    imageUrl: "/assets/quickread.png",
  },
];
