import { BiSearchAlt2 } from "react-icons/bi";
import { MdSend } from "react-icons/md";

export default function SearchBar({ search, setSearch, onSearch }) {
  return (
    <section className="flex z-20 w-full gap-4 items-center py-4 px-2 fixed bottom-0 md:px-32">
      <div
        className="flex items-center  overflow-hidden flex-[1_0_0] rounded-full bg-white "
        style={{
          boxShadow:
            "0px 0px 16px 0px rgba(0, 0, 0, 0.18), 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -4px rgba(0, 0, 0, 0.10)",
        }}>
        <div className="flex p-4 items-center gap-2 flex-[1_0_0]">
          <BiSearchAlt2 size={20} color="#9C9A96" />
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search for more content"
            className="placeholder:text-primary-neutral-300 w-full text-primary-neutral-500 font-satoshi text-xs font-medium leading-5 tracking-[0.48px] "
          />
        </div>
        <button
          disabled={search.length === 0}
          className="flex h-full py-4 cursor-pointer px-4 gap-2"
          style={{
            background: search.length === 0 ? "#CFCDC9" : "linear-gradient(159deg, #33D6E1 3.93%, #75DD67 103.93%)",
          }}
          onClick={onSearch}>
          <MdSend size={20} color="#fff" />
        </button>
      </div>
    </section>
  );
}
