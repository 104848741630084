import React, { useState, useEffect } from "react";

// APIs
import firebase from "config/firebase.js";
import { loginWithFirebaseAuthToken, verifyPhoneNumberForOTP } from "config/APIs/auth";

// Redux
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";
import { userUpdate } from "store/user";
import { ReactComponent as Morning } from "assets/svg/morning.svg";
import { ReactComponent as Afternoon } from "assets/svg/afternoon.svg";
import { ReactComponent as Evening } from "assets/svg/evening.svg";

const buttonStates = {
  init: {
    text: "Continue",
    disabled: false,
  },
  verifyNumber: {
    text: "Verifying Number",
    disabled: true,
  },
  sendOtp: {
    text: "Sending OTP",
    disabled: true,
  },
  askOtp: {
    text: "Verify OTP",
    disabled: false,
  },
  verifyOtp: {
    text: "Verifying OTP",
    disabled: true,
  },
  successOtp: {
    text: "Verified, you'll be redirected shortly",
    disabled: false,
  },
};

function LoginCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });

  const [phoneNo, setPhoneNo] = useState("");
  const [user, setUser] = useState();
  const [option, setOption] = useState("phone");
  const [code, setCode] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const [buttonState, setButtonState] = useState(buttonStates["init"]);
  function _handleTextFieldChange(e) {
    if (!isNaN(e.target.value) && e.target.value.toString().length <= 10) {
      setPhoneNo(e.target.value);
    }
  }

  const handleChange = (value1, event) => {
    if (event.target.value?.length > 1) {
    } else setCode({ ...code, [value1]: event.target.value });
  };

  const inputfocus = (e) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      const next = e.target.tabIndex - 2;
      if (next > -1) {
        e.target.form.elements[next].focus();
      }
    } else {
      const next = e.target.tabIndex;
      if (next < 6 && e.key !== "Shift") {
        e.target.form.elements[next].focus();
      }
    }
  };

  const handleClick = (number) => {
    setButtonState(buttonStates["verifyNumber"]);
    verifyPhoneNumberForOTP(number ? number : phoneNo)
      .then((res) => {
        if (res.status === 200) {
          setUser(res?.data?.data?.user);

          setButtonState(buttonStates["sendOtp"]);
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("send-otp-button", {
            size: "invisible",
          });
          const appVerifier = window.recaptchaVerifier;
          firebase
            .auth()
            .signInWithPhoneNumber("+91" + (number ? number : phoneNo), appVerifier)
            .then(function (confirmationResult) {
              setButtonState(buttonStates["askOtp"]);
              setOption("otp");
              window.confirmationResult = confirmationResult;
            });
        }
      })
      .catch((e) => {
        console.info("Err in auth number", e, "\n", e?.response);
        setButtonState(buttonStates["init"]);
        if (e?.response?.status === 404) {
          navigate("/auth/signUp", {
            state: {
              phoneNo: number,
            },
          });
        }
      });
  };

  function otpSubmit() {
    setButtonState(buttonStates["verifyOtp"]);
    let OTP = code?.otp1 + code?.otp2 + code?.otp3 + code?.otp4 + code?.otp5 + code?.otp6;
    window.confirmationResult
      .confirm(OTP)
      .then((result) => {
        setButtonState(buttonStates["successOtp"]);

        result.user.getIdToken().then((res) => {
          loginWithFirebaseAuthToken(res)
            .then((res) => {
              const { user: cred, token } = res.data.data;
              cred["role"] = "admin";
              cred["loginType"] = "phone";
              dispatch(
                userUpdate({
                  user: cred,
                  token: token,
                })
              );
              localStorage.setItem("role", "admin");
              localStorage.setItem("login_type", "phone");

              navigate("/");
            })
            .catch((e) => {
              console.log(e);
            });
        });
      })
      .catch((error) => {
        setButtonState(buttonStates["init"]);
        setOption("phone");
        console.log(error);
      });
  }

  return (
    <section className="mx-auto w-full bg-white">
      <div className="max-w-[560px] mx-auto pt-12 lg:pt-20 lg:h-auto min-h-[70vh] w-full">
        <div className="pb-6 px-6 z-10 relative bg-white">
          <div className="flex flex-col space-y-8">
            <div className="text-black font-lato text-base font-bold leading-6 tracking-[0.32px] capitalize flex flex-col items-center text-center">
              {option === "phone" ? (
                "Enter your mobile number"
              ) : (
                <div className="flex flex-row items-center justify-center space-x-4">
                  {moment().format("HH") >= 4 && moment().format("HH") < 12 ? (
                    <Morning />
                  ) : moment().format("HH") >= 12 && moment().format("HH") < 16 ? (
                    <Afternoon />
                  ) : (
                    <Evening />
                  )}{" "}
                  <p>
                    {moment().format("HH") >= 4 && moment().format("HH") < 12
                      ? "Good Morning"
                      : moment().format("HH") >= 12 && moment().format("HH") < 16
                      ? "Good Afternoon"
                      : "Good Evening"}{" "}
                    {user?.name}
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-3 w-full">
              {option === "phone" ? (
                <>
                  <div className="flex flex-col space-y-3">
                    <label
                      htmlFor="phone"
                      className="sr-only text-white text-sm lg:text-base font-semibold font-satoshi">
                      Mobile
                    </label>
                    <div className="flex p-4 justify-start font-satoshi text-lg font-normal tracking-[0.56px] items-center gap-4 self-stretch rounded-xl border border-primary-neutral-300 w-full">
                      <span className="font-bold">+91</span>
                      <input
                        name="phone"
                        type="text"
                        className="w-full !bg-white"
                        placeholder="00000 00000"
                        value={phoneNo}
                        onChange={(e) => {
                          e.target.value = e.target.value.trimStart().replace(/[^0-9]/gi, "");
                          _handleTextFieldChange(e);
                        }}
                      />
                    </div>
                    <p className="text-2xs lg:text-xs text-center font-lato text-primary-neutral-800">
                      A verification code will be sent to your mobile number{" "}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col space-y-2 w-full">
                    <label htmlFor="otp" className="sr-only text-white text-sm lg:text-base font-semibold font-satoshi">
                      Enter OTP
                    </label>
                    <form className="inputField mt-2 py-3 flex items-center justify-between text-primary-blue-dark font-normal">
                      <input
                        className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                        name="otp1"
                        type="number"
                        autoComplete="off"
                        value={code.otp1}
                        onChange={(e) => handleChange("otp1", e)}
                        tabIndex="1"
                        maxLength={1}
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                        name="otp2"
                        type="number"
                        autoComplete="off"
                        value={code.otp2}
                        onChange={(e) => handleChange("otp2", e)}
                        tabIndex="2"
                        maxLength={1}
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                        name="otp3"
                        type="number"
                        autoComplete="off"
                        value={code.otp3}
                        onChange={(e) => handleChange("otp3", e)}
                        tabIndex="3"
                        maxLength={1}
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                        name="otp4"
                        type="number"
                        autoComplete="off"
                        value={code.otp4}
                        onChange={(e) => handleChange("otp4", e)}
                        tabIndex="4"
                        maxLength={1}
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                        name="otp5"
                        type="number"
                        autoComplete="off"
                        value={code.otp5}
                        onChange={(e) => handleChange("otp5", e)}
                        tabIndex="5"
                        maxLength={1}
                        onKeyUp={(e) => inputfocus(e)}
                      />{" "}
                      <input
                        className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                        name="otp6"
                        type="number"
                        autoComplete="off"
                        value={code.otp6}
                        onChange={(e) => handleChange("otp6", e)}
                        tabIndex="6"
                        maxLength={1}
                        onKeyUp={(e) => inputfocus(e)}
                      />
                    </form>
                    <p className="text-2xs lg:text-xs text-center font-lato text-primary-neutral-800">
                      OTP Sent to mobile no. {phoneNo}
                    </p>
                  </div>{" "}
                </>
              )}
            </div>
          </div>
          <div className="submit-wrapper flex justify-end flex-col w-full">
            <button
              id="send-otp-button"
              onClick={() => {
                if (option === "phone") {
                  handleClick();
                } else {
                  otpSubmit();
                }
              }}
              style={{
                background: "linear-gradient(159deg, #33D6E1 3.93%, #75DD67 103.93%)",
              }}
              disabled={buttonState.disabled}
              className="flex max-h-14 text-white py-4 pl-10 pr-7 justify-center items-center gap-2 self-stretch rounded-full bg-black flex-[1_0_0]">
              <span className="font-satoshi font-bold leading-6">{buttonState.text}</span>
              <IoMdArrowForward />
            </button>
          </div>
        </div>

        <div className="fixed  bottom-0 right-0 left-0 p-6 flex flex-row items-center justify-center space-x-2">
          <p className="text-black font-lato text-2xs font-normal leading-4 tracking-[0.25px]">a product by </p>
          <img src="/assets/caaryaLogos/caarya.svg" alt="" className="h-8" />
        </div>
      </div>
    </section>
  );
}

export default LoginCard;
