import { ReactComponent as Logo } from "assets/svg/logoWhite.svg";
import LoginCard from "./LoginCard";

function SignIn() {
  return (
    <section className={`relative bg-white min-h-[100vh] max-w-[100vw] overflow-y-hidden flex flex-col items-center`}>
      <div className="relative w-full min-h-[230px] md:min-h-[30vh]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full absolute top-0"
          viewBox="0 0 360 210"
          fill="none"
          preserveAspectRatio="none">
          <path
            opacity="0.4"
            d="M0 -20.1094H360V150.215C296.652 153.936 262.023 209.998 178.104 209.998C94.1855 209.998 64.0447 152.943 0 152.943V-20.1094Z"
            fill="url(#paint0_linear_6701_168844)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6701_168844"
              x1="180"
              y1="2.16725"
              x2="180"
              y2="217.4"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#1E79C3" />
              <stop offset="0.404" stop-color="#0497AE" />
              <stop offset="0.634" stop-color="#33D6E1" />
              <stop offset="0.88" stop-color="#75DD67" />
              <stop offset="1" stop-color="#75DD67" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full absolute top-0"
          viewBox="0 0 360 190"
          fill="none"
          preserveAspectRatio="none">
          <path
            opacity="0.4"
            d="M360 -20.6094H0.000305176V165.525C100.856 227.059 165.67 146.226 261.716 169.574C323.706 184.643 360 180.534 360 180.534V-20.6094Z"
            fill="url(#paint0_linear_6701_168845)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6701_168845"
              x1="180"
              y1="-0.302216"
              x2="180"
              y2="195.902"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#1E79C3" />
              <stop offset="0.404" stop-color="#0497AE" />
              <stop offset="0.634" stop-color="#33D6E1" />
              <stop offset="0.88" stop-color="#75DD67" />
              <stop offset="1" stop-color="#75DD67" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full absolute top-0"
          viewBox="0 0 360 193"
          fill="none"
          preserveAspectRatio="none">
          <path
            opacity="0.4"
            d="M360 -20.1094H0.000366211V174.496C115.03 151.262 150.752 192.921 234.951 192.921C316.228 192.921 360 163.037 360 163.037V-20.1094Z"
            fill="url(#paint0_linear_6701_168846)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6701_168846"
              x1="180"
              y1="0.513987"
              x2="180"
              y2="199.773"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#1E79C3" />
              <stop offset="0.404" stop-color="#0497AE" />
              <stop offset="0.634" stop-color="#33D6E1" />
              <stop offset="0.88" stop-color="#75DD67" />
              <stop offset="1" stop-color="#75DD67" />
            </linearGradient>
          </defs>
        </svg>

        <div className="items-center relative z-10 space-y-2 lg:space-y-3 pt-6 flex flex-col lg:min-h-[26vh] justify-center">
          <Logo className="h-14 w-14 rounded-full" alt="My Vibe" />

          <div className="flex items-center gap-1">
            <p className="text-2xl font-bold font-poppins leading-6 tracking-[0.96px] text-white">my</p>
            <span className={`text-black uppercase leading-6 tracking-[1px] font-poppins text-xl font-semibold`}>
              vibe
            </span>
          </div>

          <p className="font-lato capitalize text-black text-sm font-light tracking-[0.84px]">What’s Your Vibe?</p>
        </div>
      </div>
      <LoginCard />
    </section>
  );
}

export default SignIn;
