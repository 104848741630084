/* eslint-disable no-undef */
const config = {
    environment: process.env.REACT_APP_ENV || "production",

    vibeEndpoints: {
        prod: "https://caarya-vibe-prod-tk4gwh76qa-uc.a.run.app",
        dev: "https://caarya-vibe-dev-tk4gwh76qa-uc.a.run.app",
        local: "http://localhost:3001",
    },
    awsEndpoints: {
        prod: "https://constructor-ei6fs56g4q-el.a.run.app",
        dev: "https://constructor-ei6fs56g4q-el.a.run.app",
        local: "https://constructor-ei6fs56g4q-el.a.run.app",
    },

    getEndpoint() {
        switch (this.environment) {
            case "local":
                return {
                    vibeEndpoint: this.vibeEndpoints.local,
                    awsEndpoint: this.awsEndpoints.local,
                };
            case "development":
                return {
                    vibeEndpoint: this.vibeEndpoints.dev,
                    awsEndpoint: this.awsEndpoints.dev,
                };
            case "production":
                return {
                    vibeEndpoint: this.vibeEndpoints.prod,
                    awsEndpoint: this.awsEndpoints.prod,
                };
            default:
                return {
                    vibeEndpoint: this.vibeEndpoints.dev,
                    awsEndpoint: this.awsEndpoints.dev,
                };
        }
    },
};

export default config;
