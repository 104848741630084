import { BsStars } from "react-icons/bs";
import { MdNorthEast } from "react-icons/md";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions } from "store/app";

const PreFooter = () => {
  const { ref, inView } = useInView({
    threshold: 0.4,
  });
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleModalOpen = () => {
    if (!user) {
      navigate("/auth/signIn");
      return;
    }
    dispatch(actions.setCreateModal({ open: true }));
  };
  return (
    <div ref={ref} className="relative overflow-hidden">
      <div
        className={`bottom-0 left-0 w-full text-white px-6 pt-14 pb-20 gap-8 flex flex-col items-center justify-center`}>
        <div
          className={`absolute top-2 left-0 right-0 h-full clip-path duration-[600ms] ${
            inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[100%]"
          }`}
          style={{
            // clipPath: "ellipse(80% 100% at 50% 100%)",
            clipPath: "ellipse(120% 100% at 50% 100%)",
            background: "linear-gradient(159deg, #2BB656 3.93%, #33D6E1 63.93%, #3996E3 103.93%)",
          }}></div>

        <div
          className={`z-10 flex w-full flex-col justify-center items-center pt-6 pb-20 px-4 gap-8 transition-all duration-[800ms] delay-150 lg:px-32 ${
            inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[50px]"
          }`}>
          <div className="p-4 flex flex-col gap-4 items-center justify-center">
            <BsStars color="white" size={48} />
            <div className="flex flex-col w-full">
              <p className="font-bold font-lato text-base leading-6 tracking-[0.32px] text-white text-center">
                Create Your Own Vibe!
              </p>
              <p className="font-satoshi text-center text-[#EDFFFF] text-xs font-normal leading-5">
                Create & share your vibes with curated content
              </p>
            </div>
          </div>
          <button
            onClick={handleModalOpen}
            className="flex py-4 pr-10 pl-8 w-full font-satoshi text-sm font-bold leading-5 tracking-[0.7px] justify-center items-center gap-2 self-stretch rounded-full bg-black/20">
            Create Now
            <MdNorthEast size={16} color="white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreFooter;
