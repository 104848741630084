import Drawer from "react-modern-drawer";
import { ReactComponent as Stars } from "assets/svg/congrats.svg";
import { useSelector } from "react-redux";
import { BiLogoWhatsapp } from "react-icons/bi";

export default function Congrats({ isOpen, toggleDrawer }) {
  const { title, category } = useSelector((state) => state.app.details);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="bottom"
        size="100svh"
        className="top-0 overflow-y-auto"
        lockBackgroundScroll>
        <section
          className="h-[100svh] flex py-6 px-10 gap-6 flex-[1_0_0] items-center flex-col"
          style={{
            background: "linear-gradient(159deg, #2BB656 3.93%, #33D6E1 63.93%, #3996E3 103.93%), #FFF",
          }}>
          <div className="flex flex-col justify-center items-center gap-10 flex-[1_0_0] self-stretch">
            <div className="flex flex-col justify-end items-center gap-2">
              <p className="font-lato text-white text-2xl font-bold leading-9 tracking-[0.96px]">Congratulations!</p>
              <p className="font-satoshi text-[#EDFFFF] text-sm font-medium leading-5 tracking-[0.7px]">
                All our users are now Vibing to
              </p>
            </div>
            <div className="flex py-12 px-6 flex-col items-center gap-4 bg-white rounded-2xl w-full">
              <Stars className="h-12 w-12" />
              <div className="flex flex-col items-center">
                <p className="font-lato text-[18px] tracking-[0.72px] leading-7 font-bold text-primary-neutral-800">
                  {title}
                </p>
                <p className="font-satoshi text-[12px] tracking-[0.48px] leading-5 font-normal text-primary-neutral-500">
                  {category} (theme)
                </p>
              </div>
            </div>
          </div>
          <div className="flex gap-2 flex-col items-center">
            <div className="flex p-2 gap-2 justify-center rounded-full items-center bg-black/15">
              <BiLogoWhatsapp size={28} color="white" />
            </div>
            <p className="text-black font-satoshi text-center font-medium tracking-[0.8px]">Share on Whatsapp</p>
          </div>
        </section>
      </Drawer>
    </>
  );
}
