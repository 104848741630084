import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "assets/svg/Logo.svg";
import { BsPersonFill } from "react-icons/bs";
import { MdArrowBack } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { PiSignOut } from "react-icons/pi";
import { userUpdate } from "store/user";

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state?.user);
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("user");
    dispatch(userUpdate({ user: null, token: null }));
  };

  const showBackButton = location.pathname !== "/" && location.pathname !== "/signUp";

  return (
    <div
      className="h-[68px] sticky top-0 z-10"
      style={{
        background: "radial-gradient(272.73% 50% at 50% 50%, #75DD67 0%, #33D6E1 44.9%, #FAFAFA 100%)",
      }}>
      <nav
        className={`flex h-16 py-1 flex-col justify-center items-center transition-all duration-100 ease-in ${
          location.pathname.includes("discover") ? "bg-white" : "bg-[rgba(255,255,255,0.92)] "
        } backdrop-blur-[6px] sticky top-0 z-10`}
        style={{
          background: "background: radial-gradient(272.73% 50% at 50% 50%, #008B46 0%, #0497AE 44.9%, #0F0F0F 100%)",
        }}>
        <div className="flex w-full py-1 px-6 justify-between items-center ">
          {showBackButton ? (
            <div
              className={`flex p-2 items-center gap-2 ${
                location.pathname.includes("discover") ? "bg-[rgba(0,0,0,0.05)] text-black" : "bg-white/10 text-white"
              } rounded-full cursor-pointer`}
              onClick={() => navigate(-1)}>
              <MdArrowBack size={20} />
            </div>
          ) : null}
          <div className="flex items-center gap-2 cursor-pointer" onClick={() => navigate("/")}>
            <Logo className="h-8 w-8 rounded-full" alt="My Vibe" />
            <div className="flex items-center gap-1">
              <p
                className="text-2xl font-bold font-poppins leading-7 tracking-[0.96px] bg-clip-text"
                style={{
                  background: "linear-gradient(159deg, #33D6E1 3.93%, #75DD67 103.93%)",
                  WebkitTextFillColor: "transparent",
                  WebkitBackgroundClip: "text",
                }}>
                my
              </p>
              <span className={`text-black uppercase leading-6 tracking-[1px] font-poppins text-xl font-semibold`}>
                vibe
              </span>
            </div>
          </div>
          <div
            className="flex p-2.5 justify-center items-center gap-2 rounded-full bg-primary-neutral-100 border border-[#fafafa] cursor-pointer"
            onClick={() => (!user ? navigate("/auth/signIn") : handleLogout())}>
            {!user ? <BsPersonFill color="#9C9A96" size={16} /> : <PiSignOut color="#9C9A96" size={16} />}
          </div>
        </div>
      </nav>
    </div>
  );
}
