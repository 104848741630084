import ExplorePage from "pages/Explore";
import Root from "components/common/root";
import DetailsPage from "pages/Details";
import SignIn from "pages/AuthPages/SignIn";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import SignupPage from "pages/AuthPages/Signup";

const router = createBrowserRouter([
    { path: "/auth/signIn", element: <SignIn /> },
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "/",
                element: <ExplorePage />,
            },
            {
                path: "/auth/signUp",
                element: <SignupPage />,
            },
            {
                path: "/discover",
                element: <DetailsPage />,
            },
            {
                path: "/discover/:slug",
                element: <DetailsPage />,
            },
        ],
    },
]);

export default function App() {
    return (
        <>
            <RouterProvider router={router} />
        </>
    );
}
