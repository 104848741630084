import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./navbar";
import { useEffect, useRef } from "react";
import TabBar from "./tabbar";
import NewVibeModal from "components/newVibe/newVibeModal";

export default function Root() {
  const mainRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [mainRef, location.pathname]);

  return (
    <>
      <Navbar />
      <NewVibeModal />

      <main
        ref={mainRef}
        style={{
          scrollMargin: "200px",
        }}>
        <Outlet />
      </main>
      {!location.pathname.includes("auth") && <TabBar />}
    </>
  );
}
